// // api.js

// import axios from 'axios';

// const serverUrl = process.env.REACT_APP_BACKEND_URL;
// axios.defaults.withCredentials = true;

// export const setCookie = async () => {
//   return axios.get(`${serverUrl}/set-cookie`);
// };
// setCookie();

// export const api = axios.create({
//   baseURL: serverUrl, // Replace with your API base URL
// });

// src/axiosWrapper.js

import axios from 'axios';

const serverUrl = process.env.REACT_APP_BACKEND_URL;
axios.defaults.withCredentials = true;

export const api = axios.create({
  baseURL: serverUrl,
});

// Function to fetch and set CSRF token
export const setCsrfToken = async () => {
  try {
    const response = await axios.get(`${serverUrl}/set-cookie`);
    const csrfToken = response.data.csrfToken;
    api.defaults.headers.common['X-CSRF-Token'] = csrfToken;
  } catch (error) {
    console.error('Error fetching CSRF token:', error);
  }
};

// Fetch and set CSRF token on initialization
setCsrfToken();
