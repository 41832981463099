import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import FilterListIcon from '@mui/icons-material/FilterList';
import Button from '@mui/material/Button';
import Fade from '@mui/material/Fade';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import IssueBox from './IssueBox';
import LineGraphs from './lineGraphs';

import error from '../../info-logos/Error-display.png';
import success from '../../info-logos/Success-display.png';
import MobileLineGraph from '../MobileLineGraph/MobileLineGraph';

function AWSHealthDashboard(props) {
  const { loading } = props;
  const [open, setOpen] = useState(false);
  const [issues, setIssues] = useState([]);
  const [dailyGapCSS, setDailyGapCSS] = useState(false);
  const elementRef = React.useRef([]);
  const [sortStatus, setSortStatus] = useState(true);
  const [apiData, setApiData] = useState(props.apiData ? props.apiData : []);

  useEffect(() => {
    if (apiData.length === 0 && props.apiData) {
      setApiData(props.apiData);
    }
  }, [props.apiData]);

  const categoryLabels = {
    0: 'Products',
    1: 'Devices',
  };

  const buttonText = categoryLabels[props.category] || 'Services';

  const handleSortIf = () => {
    let sorted = apiData.data.sort((b, a) =>
      a.serviceName.localeCompare(b.serviceName),
    );

    setApiData({ data: sorted, empty: apiData.empty });
    setSortStatus(false);
  };

  const handleSortElse = () => {
    let sorted = apiData.data.sort((a, b) =>
      a.serviceName.localeCompare(b.serviceName),
    );

    setApiData({ data: sorted, empty: apiData.empty });
    setSortStatus(true);
  };

  const sortFunctions = {
    true: handleSortIf,
    false: handleSortElse,
  };
  const handleSort = status => {
    const sortingFunction = sortFunctions[status];
    sortingFunction();
  };

  const renderSwitch = param => {
    return param === 'No Issues' ? success : error;
  };

  const Interchange_to_hourly = () => {
    setDailyGapCSS(false);
  };

  const handleClick = (issues, setOpen, setIssues) => {
    if (issues?.issueDescription?.length > 0) {
      setOpen(true);
      setIssues(issues);
    }
  };

  const sortOrder = sortStatus ? 'descending' : 'ascending';
  const title = `Sort ${buttonText} in ${sortOrder} order`;

  if (window.innerWidth < 1025) {
    return (
      <div>
        {loading ? (
          <div className="table-loader-container">
            <div className="loader" />
          </div>
        ) : apiData && apiData.data && apiData.data.length > 0 ? (
          apiData.data.map((api, index) => (
            <>
              <Row className="row-chart" key={index} xs={12}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    className="header-api-mobile"
                    style={{ minWidth: '150px' }}
                  >
                    <p className="api-name-list">
                      {/* Incase characters are more than 30, put ellipsis. */}
                      {api?.serviceName?.length > 60
                        ? api.serviceName?.substring(0, 20) + `...`
                        : api.serviceName}
                    </p>
                  </div>
                  <div
                    style={{
                      maxWidth: window.innerWidth * 1,
                      overflowX: 'auto',
                    }}
                    id="status-indicator-container"
                  >
                    <div
                      className={'hour-display'}
                      id="status-indicator"
                      style={{
                        width: '980px',
                        justifyContent: 'space-between',
                      }}
                    >
                      {' '}
                      {api.issues?.map(({ issues }, key) => (
                        <span key={key}>
                          <img
                            src={renderSwitch(issues)}
                            className="row-alert-render"
                            onClick={() =>
                              handleClick(issues, setOpen, setIssues)
                            }
                            style={{
                              marginBottom: '20px',
                            }}
                            alt="alert-message"
                          />
                        </span>
                      ))}
                    </div>
                    <MobileLineGraph graphData={api.issues} />
                  </div>
                </div>
              </Row>
            </>
          ))
        ) : (
          <div
            className="coming-soon"
            style={{ textAlign: 'center', fontSize: '18px' }}
          >
            <p>Coming very soon...</p>
          </div>
        )}
      </div>
    );
  }

  return (
    <>
      <IssueBox open={open} setOpen={setOpen} issues={issues} />
      <Stack direction="row" justifyContent={'space-between'}>
        <Stack
          spacing={9}
          direction="row"
          sx={{
            marginLeft: '-10px',
          }}
        >
          <Tooltip
            arrow
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            placement="right"
            title={title}
          >
            <div
              onClick={() => {
                handleSort(sortStatus);
              }}
            >
              <Button
                variant="text"
                className="Button-display-tablep"
                data-testid="sort-button-aws"
                sx={{
                  fontSize: '14px',
                  fontWeight: '600',
                  textTransform: 'unset',
                }}
              >
                {buttonText}
              </Button>
              <IconButton aria-label="delete">
                <FilterListIcon className="image-sort" />
              </IconButton>
            </div>
          </Tooltip>
        </Stack>
        <Stack className="hourly-daily-display" direction="row">
          <Button
            variant="text"
            className="Button-display-tableh"
            sx={{
              textTransform: 'unset',
            }}
            disabled={true}
          >
            ( Note: Data auto-updates every 5 minutes )
          </Button>
          <Button
            variant="text"
            className="Button-display-tableh"
            sx={{
              textTransform: 'unset',
              fontWeight: dailyGapCSS ? '400' : '700',
            }}
            onClick={() => Interchange_to_hourly()}
          >
            Hourly*
          </Button>
          <Button
            variant="text"
            className="Button-display-tableh"
            sx={{
              textTransform: 'unset',
              fontWeight: 400,
            }}
            disabled={true}
          >
            *All time in UTC
          </Button>
        </Stack>
      </Stack>
      <div>
        {apiData && apiData.data && apiData.data.length > 0 ? (
          apiData.data.map((api, index) => (
            <Row className="row-chart" key={index} xs={12}>
              <Col xs={2}>
                <div className="header-api">
                  <p className="api-name-list">{api.serviceName}</p>
                </div>
              </Col>
              <Col
                id="column80"
                xs={10}
                ref={el => (elementRef.current[index] = el)}
              >
                <div className={'hour-display'} id="status-indicator">
                  {api.issues?.map(({ issues }, key) => (
                    <span key={key}>
                      <img
                        src={renderSwitch(issues)}
                        className="row-alert-render"
                        style={{
                          cursor:
                            issues?.issueDescription?.length > 0
                              ? 'pointer'
                              : '',
                        }}
                        onClick={() => handleClick(issues, setOpen, setIssues)}
                        alt="alert-message"
                      />
                    </span>
                  ))}
                </div>
                <Row>
                  {api.issues ? (
                    <LineGraphs issues={api.issues} loading={loading} />
                  ) : null}
                </Row>
              </Col>
            </Row>
          ))
        ) : (
          <div
            className="coming-soon"
            style={{ textAlign: 'center', fontSize: '18px' }}
          >
            <p>Coming very soon... </p>
          </div>
        )}
      </div>
    </>
  );
}

export default AWSHealthDashboard;