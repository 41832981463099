
export const mapRegiontoIndex = {
  Americas: 0,
  Europe: 1,
  'Asia Pacific': 2,
};

export const cspOptions = [
  { label: 'Azure', value: 'Azure' },
  { label: 'AWS', value: 'AWS' },
  { label: 'GCP', value: 'GCP' },
];

export const getEnvProdCheck = () => {
  return process.env.REACT_APP_ENV === 'prod';
};

export const getEnvNpdCheck = () => {
  return process.env.REACT_APP_ENV === 'dev';
};

export const getEnvLocalCheck = () => {
  return process.env.REACT_APP_ENV === 'local';
};

export const getAppURL = () => {
  return getEnvProdCheck()
    ? process.env.REACT_APP_PRD_URL
    : process.env.REACT_APP_DEV_URL;
};
