import React, { useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis } from 'recharts';

import '../LineGraph/linegraph.css';

//custom tooltip
export default function MobileLineGraph(props) {
  const CustomTooltip = ({ active, payload, label }) => {
    let param = payload[0]?.payload?.availability;
    if (active && payload && payload.length) {
      return (
        <div className="TooltipBox">
          <div className="custom-tooltip">
            <p className="TooltipLabel">DateTime : </p>
            <p className="TooltipData">{` ${
              payload[0]?.payload?.ISODate || payload[0]?.payload?.hour
            }`}</p>
          </div>
          <div className="custom-tooltip">
            <p className="TooltipLabel">Availability : </p>
            <p
              className="TooltipData"
              style={{
                color:
                  param === 1
                    ? '#86bc25'
                    : param < 10
                      ? 'red'
                      : param > 11 && param < 90
                        ? '#ed8b00'
                        : '#86bc25',
              }}
            >
              {payload[0]?.payload?.category === 'cloudNetwork'
                ? `${payload[0]?.payload?.availability * 100}`
                : `${payload[0]?.payload?.availability?.toFixed(2)}`}
            </p>
          </div>
        </div>
      );
    }

    return null;
  };
  const [loading, setLoading] = useState(true);
  const { graphData } = props || {};

  const formatXAxis = tickItem => {
    return tickItem.substring(11, 16);
  };

  useEffect(() => {
    let load_initiate = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      clearTimeout(load_initiate);
    };
  }, [props]);

  if (loading) {
    return <div className="loader-graph" data-testid="loader-graph"></div>;
  }
  if (graphData && graphData.length > 0) {
    return (
      <div>
        <LineChart
          height={70}
          width={
            document.getElementById('status-indicator').offsetWidth * 1.012
          }
          style={{
            marginLeft: '-15px',
          }}
          data={graphData}
          margin={{ top: 5, left: 20, right: 3, bottom: 5 }}
        >
          <CartesianGrid
            vertical={false}
            horizontal={false}
            strokeDasharray="#FFFFFF"
            fill="rgba(217, 217, 217, 0.5)"
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type="monotone"
            dataKey="availability"
            stroke="#00A3E0"
            strokeWidth={2}
            dot={{ stroke: '#00A3E0', strokeWidth: 5 }}
            activeDot={{ r: 4 }}
          />
          <XAxis
            dataKey="hour"
            interval="preserveStartEnd"
            tickFormatter={formatXAxis}
            style={{ fontSize: '10px' }}
          />
        </LineChart>
      </div>
    );
  }
  return (
    <div className="empty-graph-data-display">
      <p> No Data to show. Graph error.</p>
    </div>
  );
}
