import React, { PureComponent, useEffect, useState } from 'react';
import { LoaderIcon } from 'react-hot-toast';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// const data = [
//   {
//     name: 'Page A',
//     Open: 4000,
//     Closed: 2400,
//   },
//   {
//     name: 'Page B',
//     Open: 3000,
//     Closed: 1398,
//   },
//   {
//     name: 'Page C',
//     Open: 2000,
//     Closed: 9800,
//   },
//   {
//     name: 'Page D',
//     Open: 2780,
//     Closed: 3908,
//   },
// ];

const MicBarChart = payload => {
  const [loading, setLoading] = useState(true);

  const title = payload.title;
  const data = payload.data?.map(e => {
    return { ...e, created_at: new Date(e.created_at).toLocaleDateString() };
  });
  if (!data) {
    return <LoaderIcon />;
  }
  return (
    <div style={{ width: '100%', height: '100%' }}>
      <h4
        style={{
          color: '#046a38',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        {title}
      </h4>
      <ResponsiveContainer width="100%" height="90%">
        <BarChart
          width={'100%'}
          height={500}
          data={data}
          margin={{
            top: 15,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="created_at" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="Open"
            fill="#046a38"
            label={{ position: 'top' }}
            // activeBar={<Rectangle fill="pink" stroke="blue" />}
          />
          <Bar
            dataKey="Closed"
            fill="#000"
            label={{ position: 'top' }}
            // activeBar={<Rectangle fill="gold" stroke="purple" />}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MicBarChart;
