import * as React from 'react';
import { useEffect, useState } from 'react';
import { MenuItem, Select } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import axios from 'axios';

import AWSHealthDashboard from '../../AWShealthDashboard/awsHealthDashboard';
import TableHealth from '../../HealthCard/HealthCard';
import { StyledPopper } from '../../SubscriptionModal/utils/CustomAutocomplete/customAutocomplete';

import '../DesktopNavBar/navbar.css';
import '../../../LoaderSpinner/loaderspinner.css';
import {
  categoryMapper,
  deviceTypeMapper,
  mapCategoryToIndex,
  regions,
  regionsMapper,
} from '../NavbarRegionMappings/generalRegionMapper';
import {
  awsRegions,
  awsRegionsMapper,
} from '../NavbarRegionMappings/awsRegions';
import {
  gcpRegions,
  gcpRegionsMapper,
} from '../NavbarRegionMappings/gcpRegions';
import {
  azureRegions,
  azureRegionMapper,
} from '../NavbarRegionMappings/azureRegions';
import {
  a11yProps,
  filterOptions,
  MobileTabWithCount,
} from '../TabPanel/TabPanel';
import { useDispatch, useSelector } from 'react-redux';
import { setHardRefresh } from '../../../redux/userPreferencesSlice';

export default function NewMobileNavBar() {
  const { userDefaultThemeView, apiCalled } =
    useSelector(state => state.userInfo) || {};
  const [value, setValue] = React.useState(0);
  const [cmpApiData, setCmpApiData] = useState({ data: [], empty: 'false' });
  const [cloudApiData, setCloudApiData] = useState({
    data: [],
    empty: 'false',
  });
  const [awsGcpAzureData, setAwsGcpAzureData] = useState({
    data: [],
    empty: 'false',
  });

  const [regionActive, setRegionActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [csp, setCSP] = useState('Azure');
  const [cachedApiData, setCachedApiData] = useState([]);
  const [searchLabelText, setSearchLabelText] = useState('');
  const [categorySelector, setCategorySelector] = useState(
    deviceTypeMapper[0].value,
  );
  const [cspSelectedRegion, setCspSelectedRegion] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);
  //Triggers for user default view
  const [automaticTriggerHelperFlag, setAutomaticTriggerHelperFlag] =
    useState(false);
  const [automaticTrigger, setAutomaticTrigger] = useState(false);
  const dispatch = useDispatch();
  const hardRefresh = useSelector(state => state.userInfo.hardRefresh);

  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchCMPData = async () => {
    setLoading(true);
    axios
      .get(
        `${serverUrl}/api/${categoryMapper[value]}`, //CMP call
      )
      .then(res => {
        if (res?.data?.length > 0) {
          const result = res.data.sort((a, b) => a._id.localeCompare(b._id));
          setCmpApiData({ data: result, empty: 'false' });

          setCachedApiData({ data: result, empty: 'false' });
        } else {
          setCmpApiData({ data: [], empty: 'true' });
        }
        setLoading(false);
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  };
  const fetchCloudData = async () => {
    setLoading(true);
    axios
      .get(
        `${serverUrl}/api/cloud/${categorySelector}/${regionsMapper[regionActive]}?csp=${csp}`, //Cloud Network (Palo_Alto)
      )
      .then(res => {
        // console.log(res);
        if (res && res.data.length > 0) {
          setCloudApiData({ data: res.data, empty: 'false' });
          setCachedApiData({ data: res.data, empty: 'false' });
        } else {
          setCloudApiData({ data: [], empty: 'true' });
        }
        setLoading(false);
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetch_AWS_GCP_AZURE_Data = async () => {
    setLoading(true);
    const apiEndpoint = value === 3 ? 'aws' : value === 4 ? 'gcp' : 'azure';

    axios
      .get(`${serverUrl}/api/${apiEndpoint}/${cspSelectedRegion}`)
      .then(res => {
        if (res?.data?.length > 0) {
          // console.log(res.data)
          setAwsGcpAzureData({ data: res.data, empty: 'false' });
          setCachedApiData({ data: res.data, empty: 'false' });
        } else {
          setAwsGcpAzureData({ data: [], empty: 'true' });
        }
      })
      .catch(err => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = async () => {
    if (value === 0) {
      fetchCMPData();
    } else if (value === 1) {
      fetchCloudData();
    } else if (value === 2 || value === 3 || value === 4) {
      fetch_AWS_GCP_AZURE_Data();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);

    // Set the default region for the new tab
    if (newValue === 2) {
      setCspSelectedRegion('americas');
      setRegionActive(0);
    } else if (newValue === 3 || newValue === 4) {
      setCspSelectedRegion('global');
      setRegionActive(0);
    }
  };
  const setRegionActiveButton = index => {
    setRegionActive(index);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#00a3e0',
      },
      secondary: {
        main: 'rgb(124, 124, 124)',
      },
    },
  });

  const cspOptions = [
    { label: 'Azure', value: 'Azure' },
    { label: 'AWS', value: 'AWS' },
    { label: 'GCP', value: 'GCP' },
  ];

  const handleSearchChange = (e, v) => {
    // console.log(v?._id);
    setSearchString(v?._id || v?.serviceName || '');
  };
  const isMobileScreen = useMediaQuery(
    '(min-width:320px) and (max-width:767px)',
  );
  const isTabletScreen = useMediaQuery(
    '(min-width:768px) and (max-width:1024px)',
  );

  const getTopValue = () => {
    if (isMobileScreen) return '38px';
    if (isTabletScreen) return '50px';
  };

  const mapRegionToIndex = (region, category) => {
    const awsRegionMap = {
      global: 0,
      north_america: 1,
      south_america: 2,
      europe: 3,
      africa: 4,
      asia_pacific: 5,
      middle_east: 6,
    };

    const cloudNetworkRegionMap = {
      Americas: 0,
      Europe: 1,
      'Asia Pacific': 2,
    };

    const azureRegionMap = {
      americas: 0,
      europe: 1,
      asia_pacific: 2,
    };

    const gcpRegionMap = {
      global: 0,
      americas: 1,
      europe: 2,
      africa: 3,
      asia_pacific: 4,
      middle_east: 5,
    };

    switch (category) {
      case 'AWS':
        return awsRegionMap[region];
      case 'AZURE':
        return azureRegionMap[region];
      case 'GCP':
        return gcpRegionMap[region];
      case 'Cloud Network':
        return cloudNetworkRegionMap[region];
      default:
        return null;
    }
  };

  const handlePreferredView = preferred => {
    const categoryIndex = mapCategoryToIndex[preferred.category_name];
    setAutomaticTrigger(true);
    setAutomaticTriggerHelperFlag(true);
    setValue(categoryIndex);

    switch (preferred.category_name) {
      case 'Cloud Network':
        setRegionActive(mapRegionToIndex(preferred.region, 'Cloud Network'));
        setCategorySelector(preferred.device_type);
        setCSP(preferred.csp);
        break;
      case 'AWS':
        handleAWSView(preferred);
        break;
      case 'AZURE':
        setCspSelectedRegion(preferred.region);
        setRegionActive(mapRegionToIndex(preferred.region, 'AZURE'));
        break;
      case 'GCP':
        setCspSelectedRegion(preferred.region);
        setRegionActive(mapRegionToIndex(preferred.region, 'GCP'));
        break;
      default:
        break;
    }
  };

  const handleAWSView = preferred => {
    const regionIndex = mapRegionToIndex(preferred.region, 'AWS');

    if (cspSelectedRegion !== preferred.region) {
      setCspSelectedRegion(preferred.region);
    }
    if (regionActive !== regionIndex) {
      setRegionActive(regionIndex);
    }
  };

  //done
  useEffect(() => {
    switch (value) {
      case 0:
        setSearchLabelText('Search CMP Products');
        break;
      case 1:
        setSearchLabelText(
          `Search ${
            categorySelector === 'F5' ? 'F5' : 'Palo Alto'
          } Devices by IP or Name`,
        );
        break;
      case 2:
        setSearchLabelText('Search Azure Services');
        break;
      case 3:
        setSearchLabelText('Search AWS Services');
        break;
      case 4:
        setSearchLabelText('Search GCP Services');
        break;
      default:
        setSearchLabelText('Search Services');
    }
  }, [value, categorySelector]);

  //done
  useEffect(() => {
    if (!automaticTriggerHelperFlag) {
      setRegionActive(0);
      if (value === 2) {
        setCspSelectedRegion('americas');
      } else if ([3, 4].includes(value)) {
        if (cspSelectedRegion === 'global') {
          fetchData();
        } else {
          setCspSelectedRegion('global');
        }
      } else {
        fetchData();
      }
    } else {
      setAutomaticTriggerHelperFlag(false);
      fetchData();
    }
  }, [value]);

  //done
  useEffect(() => {
    if (automaticTrigger && !automaticTriggerHelperFlag) {
      fetchData();
    } else {
      //console.log("Not triggering, automaticTrigger", automaticTrigger, "automaticTriggerHelperFlag", automaticTriggerHelperFlag)
    }
  }, [regionActive, categorySelector, csp, cspSelectedRegion]);

  //done
  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 90000);

    return () => clearInterval(interval);
  }, [regionActive, value]);
  // }, [regionActive, value, categorySelector, csp]);

  //done
  useEffect(() => {
    setSearchString('');
    setSelectedOption(null);
  }, [
    value,
    categorySelector,
    cspSelectedRegion,
    gcpRegions,
    csp,
    regionActive,
  ]);

  //done
  useEffect(() => {
    if (searchString === '' || !searchString) {
      if (value === 0) {
        setCmpApiData(cachedApiData);
      } else if (value === 1) {
        setCloudApiData(cachedApiData);
      } else {
        setAwsGcpAzureData(cachedApiData);
      }
    } else {
      const result = cachedApiData.data.filter(item => {
        const id = item?._id?.toLowerCase();
        const serviceName = item?.serviceName?.toLowerCase();
        return (id || serviceName).includes(searchString.toLowerCase());
      });
      if (value === 0) {
        setCmpApiData({ data: result, empty: 'false' });
      } else if (value === 1) {
        setCloudApiData({ data: result, empty: 'false' });
      } else {
        setAwsGcpAzureData({ data: result, empty: 'false' });
      }
    }
  }, [searchString]);

  useEffect(() => {
    setSearchString('');
    if (apiCalled && !automaticTrigger) {
      if (userDefaultThemeView?.view) {
        const { view } = userDefaultThemeView;
        if (view) {
          const { preferred } = view;

          if (preferred.exists) {
            handlePreferredView(preferred);
          } else {
            setAutomaticTrigger(true);
            setAutomaticTriggerHelperFlag(true);
          }
        }
      } else {
        setAutomaticTrigger(true);
        setAutomaticTriggerHelperFlag(true);
      }
    }
  }, [value, userDefaultThemeView, apiCalled]);

  useEffect(() => {
    if (hardRefresh) {
      handlePreferredView(userDefaultThemeView.view.preferred);
      dispatch(setHardRefresh());
    }
  }, [userDefaultThemeView, hardRefresh]);

  return (
    <Box
      sx={{ width: '100%' }}
      className="service-insight-label"
      data-testid="new-mobile-navbar"
    >
      <Box>
        <Box
          component="span"
          sx={{ width: '100%', margin: '0px' }}
          m={1}
          display="flex"
          flexDirection={['column']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            TabIndicatorProps={{
              style: {
                display: 'flex',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                position: 'absolute',
                // left: "7px",
                // width: "45px",
                background: 'rgb(0, 163, 224)',
                height: '4px',
                top: getTopValue(),
                // marginLeft: "5px",
              },
            }}
            sx={{
              width: '100%',
              '& .MuiTabs-flexContainer': {
                display: 'flex',
                justifyContent: 'space-between',
              },
            }}
            value={value}
            onChange={handleChange}
            aria-label="Tabs for Category Selection"
          >
            <Tab
              sx={{ minWidth: '40px', padding: '13px 10px' }}
              label={
                <MobileTabWithCount
                  count="CMP"
                  width="54px"
                  height="40px"
                ></MobileTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{ minWidth: '40px', padding: '13px 10px' }}
              label={
                <MobileTabWithCount
                  count="Cloud"
                  width="54px"
                  height="40px"
                ></MobileTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{ minWidth: '40px', padding: '13px 10px' }}
              label={
                <MobileTabWithCount count="Azure" width="48px" height="30px">
                  Azure
                </MobileTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{ minWidth: '40px', padding: '13px 10px' }}
              label={
                <MobileTabWithCount count="AWS" width="44px" height="30px">
                  AWS
                </MobileTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              sx={{ minWidth: '40px', padding: '13px 10px' }}
              label={
                <MobileTabWithCount
                  count="GCP"
                  width="34px"
                  height="30px"
                ></MobileTabWithCount>
              }
              {...a11yProps(0)}
            />
          </Tabs>

          {(value === 0 || [1, 2, 3, 4].includes(value)) && (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <Autocomplete
                disablePortal
                key={value}
                className="search_bar"
                filterOptions={filterOptions}
                id="combo-box-demo"
                PopperComponent={StyledPopper} // Use the styled Popper component
                getOptionLabel={healthData =>
                  healthData._id || healthData.serviceName || ''
                }
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderRadius: '10px',
                    padding: '0px',
                    border: '1px solid var(--search_bar_outline)',
                  },
                  width: 300,
                  color: 'var(--hour_color)',
                  '.MuiAutocomplete-inputRoot': {
                    display: 'flex',
                    alignItems: 'center',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    paddingRight: '30px !important', // Adjust padding to create space for the "x" mark
                  },
                }}
                onChange={(e, v) => {
                  setSelectedOption(v); // Update the selected option state
                  handleSearchChange(e, v); // Call your existing change handler
                }}
                value={selectedOption}
                options={
                  value === 0
                    ? cmpApiData.data || []
                    : value === 1
                      ? cloudApiData.data || []
                      : awsGcpAzureData.data || []
                }
                renderInput={params => (
                  <TextField
                    className="autocomplete-search-text-field"
                    {...params}
                    label={searchLabelText}
                    sx={{
                      color: 'var(--hour_color)',
                      '& .MuiInputLabel-root': {
                        top: '-7px',
                        color: 'var(--search_service_label_color)',
                      },
                      '& .MuiSvgIcon-root': {
                        color: 'var(--search_service_label_color)',
                      },
                      '.MuiInputBase-input': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        paddingRight: '30px !important', // Ensure this matches the padding added to the input root
                      },
                    }}
                  />
                )}
              />

              {[2, 3, 4].includes(value) && (
                <div
                  className="region-dropdown-flex-mobile"
                  style={{ marginLeft: '10px' }}
                >
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <label className="region-selection-label-mobile">
                      Region &nbsp;&nbsp;
                    </label>
                    <Select
                      data-testid="region-selection-mobile"
                      className="region-selection-mobile"
                      MenuProps={{
                        MenuListProps: {
                          style: {
                            backgroundColor: 'var(--search_bar_dark_bg)',
                            color: 'var(--body_color)',
                          },
                        },
                      }}
                      value={regionActive}
                      onChange={e => {
                        const selectedRegionMapper =
                          value === 2
                            ? azureRegionMapper
                            : value === 3
                              ? awsRegionsMapper
                              : gcpRegionsMapper;
                        setCspSelectedRegion(
                          selectedRegionMapper[e.target.value],
                        );
                        setRegionActive(e.target.value);
                      }}
                      variant="outlined"
                    >
                      {(value === 2
                        ? azureRegions
                        : value === 3
                          ? awsRegions
                          : gcpRegions
                      ).map((region, index) => (
                        <MenuItem key={index} value={index}>
                          {region.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}
            </div>
          )}
        </Box>

        <ThemeProvider theme={theme}>
          {value === 1 && (
            <div className="region-dropdown-flex-mobile">
              <div className="region-dropdown-flex-mobile-inner">
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '10px',
                  }}
                >
                  <p className="region-selection-label-mobile">
                    Region &nbsp;&nbsp;
                  </p>
                  <Select
                    className="region-selection-mobile"
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          backgroundColor: 'var(--search_bar_dark_bg)',
                          color: 'var(--body_color)',
                        },
                      },
                    }}
                    value={regionActive}
                    onChange={e => {
                      setRegionActive(e.target.value);
                      setRegionActiveButton(e.target.value);
                    }}
                    variant="outlined"
                  >
                    {regions.map((region, index) => (
                      <MenuItem key={index} value={index}>
                        {region.region}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginRight: '10px',
                  }}
                >
                  <p className="device-selection-label-mobile">
                    Select Device &nbsp;&nbsp;
                  </p>
                  <Select
                    className="device-selection-mobile"
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          backgroundColor: 'var(--search_bar_dark_bg)',
                          color: 'var(--body_color)',
                        },
                      },
                    }}
                    onChange={e => {
                      setCategorySelector(e.target.value);
                    }}
                    value={categorySelector}
                  >
                    {deviceTypeMapper.map((e, i) => (
                      <MenuItem key={i} value={e.value}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p className="csp-selection-label-mobile">CSP &nbsp;&nbsp;</p>
                  <Select
                    className="device-selection-mobile"
                    MenuProps={{
                      MenuListProps: {
                        style: {
                          backgroundColor: 'var(--search_bar_dark_bg)',
                          color: 'var(--body_color)',
                        },
                      },
                    }}
                    onChange={e => {
                      setCSP(e.target.value);
                    }}
                    value={csp}
                  >
                    {cspOptions.map((e, i) => (
                      <MenuItem key={i} value={e.value}>
                        {e.label}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </ThemeProvider>
      </Box>
      {loading ? (
        <div className="table-loader-container">
          <div className="loader" />
        </div>
      ) : [2, 3, 4].includes(value) ? (
        <AWSHealthDashboard apiData={awsGcpAzureData} loading={loading} />
      ) : (
        <TableHealth
          apiData={value === 0 ? cmpApiData : cloudApiData}
          category={value}
          loading={loading}
        />
      )}
    </Box>
  );
}
