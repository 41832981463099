import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Snackbar from '@mui/material/Snackbar';
import TextField from '@mui/material/TextField';

import './newSubscriptionsList.css';
export default function NewSubscriptionsList({
  subscriptions,
  onUpdateSubscriptions,
  disabled,
  selectedOptions,
  setSelectedOptions,
  resetOptions,
}) {
  // const [selectedValue, setSelectedValue] = React.useState([]);
  const handleUpdate = (e, v) => {
    setSelectedOptions(v); // Pass the selected value to the parent callback
    // onUpdateSubscriptions(v); // Pass the selected value to the parent callback
  };
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const mobileSx = {
    maxHeight: '150px',
    overflowY: 'auto',
    '.MuiOutlinedInput-root': {
      borderRadius: '10px',
      padding: '0px',
      border: '1px solid var(--search_bar_outline)',
    },
    width: '19rem',
    color: 'var(--hour_color)',
    '.MuiAutocomplete-tag': {
      width: '100%',
      margin: '7px',
      paddingRight: '0', // Remove right padding
      display: 'flex',
      justifyContent: 'center', // Center the content horizontally
    },
    '.MuiAutocomplete-tag .MuiChip-deleteIcon': {
      position: 'absolute',
      right: '5px', // Adjust position of delete icon
    },
    '.MuiAutocomplete-tag .MuiChip-label': {
      maxWidth: 'calc(100% - 24px)', // Adjust text width with margin for delete icon
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  };

  const desktopSx = {
    '.MuiOutlinedInput-root': {
      borderRadius: '10px',
      padding: '0px',
      border: '1px solid var(--search_bar_outline)',
    },
    width: '32.5rem',

    color: 'var(--hour_color)',
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
        ContentProps={{
          style: {
            backgroundColor: 'rgb(213 64 51)',
            fontSize: '13px',
            color: 'white',
          },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        message="Dropdown disabled. Please select an option in the dropdown above."
      />
      {disabled ? (
        <div
          style={{
            width: window.screen.width > 600 ? '85%' : '19rem',
            padding: '7px',
            minWidth: '360px',
            borderRadius: '10px',
            cursor: 'not-allowed',
            display: 'flex',
            flexDirection: 'row',
            border: 'var(--disable-border)',
          }}
          onClick={handleClick}
          data-testid="disabled-container"
        >
          <div
            style={{
              flex: '85%',
              fontSize: '13px',
              color: 'hsl(0 0% 53% / 1)',
            }}
          >
            Disabled
          </div>
          <div
            style={{
              padding: '0px 5px 0px 12px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              borderLeft: 'var(--disable-border-left)',
            }}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
              style={{ fill: 'var(--disable-svg-fill)' }}
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
            </svg>
          </div>{' '}
        </div>
      ) : (
        <Autocomplete
          disablePortal
          freeSolo
          multiple
          className="search_bar-mobile"
          id="combo-box-demo"
          getOptionLabel={healthData => healthData?.product_name || ''}
          sx={window.screen.width > 600 ? desktopSx : mobileSx}
          onChange={(e, v) => handleUpdate(e, v)}
          options={subscriptions}
          key={resetOptions}
          renderInput={params => (
            <TextField
              className="autocomplete-search-text-field"
              {...params}
              label={'Search'}
              sx={{
                color: 'var(--hour_color)',
                fontSize: window.screen.width < 600 ? '14px' : '16px',
                '& .MuiInputLabel-root': {
                  top: '-7px',
                  color: 'var(--search_service_label_color)',
                  fontSize: window.screen.width < 600 ? '14px' : '16px',
                },
                '& .MuiSvgIcon-root': {
                  color: 'var(--search_service_label_color)',
                },
                '& .MuiButtonBase-root.MuiChip-root': {
                  backgroundColor:
                    'var(--new-subscription-service-multi-select)',
                },
                '& .MuiButtonBase-root .MuiChip-deleteIcon': {
                  '-webkit-tap-highlight-color': 'transparent',
                  color: 'rgba(0, 0, 0, 0.26)',
                },
              }}
            />
          )}
        />
      )}
    </>
  );
}
