import React from 'react';

function ClearAllButton({ clearAllClicked }) {
  return (
    <div>
      <span>
        <button
          className="update-button"
          data-testid="clear-all"
          onClick={clearAllClicked}
        >
          Clear All
        </button>
      </span>
    </div>
  );
}

export default ClearAllButton;
