export const azureRegionMapper = {
    0: 'americas',
    1: 'europe',
    2: 'asia_pacific',
};

export const azureRegions = [
    { region: 'americas', label: 'Americas' },
    { region: 'europe', label: 'Europe' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
];