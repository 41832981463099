import React, { useEffect, useState } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import '../../../pages/MicReport/mic-report.css';

const COLORS = ['#046a38', 'red']; // Red for open, green for closed

const renderLegend = props => {
  const { payload } = props;
  return (
    <ul className="custom-legend">
      {payload.map((entry, index) => (
        <li key={`item-${index}`} className="legend-item">
          <span
            className="legend-color-box"
            style={{ backgroundColor: entry.color }}
          ></span>
          <span
            style={{ color: '#000000', fontSize: '1rem', marginLeft: '5px' }}
          >
            {`${entry.value}: ${entry.payload.value}`}
          </span>
        </li>
      ))}
    </ul>
  );
};

const MicPieChart = ({ title, data }) => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // Simulate data fetching
  //   const fetchData = async () => {
  //     // Simulate a delay
  //     await new Promise(resolve => setTimeout(resolve, 2000));
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    ); // Display a loading indicator
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <h4
        style={{
          color: '#046a38',
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        {title} SLA
      </h4>
      <ResponsiveContainer width="100%" height="85%">
        <PieChart width={400} height={400}>
          <Pie
            dataKey="value"
            data={data}
            innerRadius={90}
            outerRadius={110}
            fill="#046a38"
            label={({ name, value, percent }) =>
              `${value} (${(percent * 100).toFixed(2)}%)`
            }
            legendType="square"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={COLORS[index % COLORS.length]}
                stroke="gray" // Gray border
                strokeWidth={1} // Border width
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend content={renderLegend} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MicPieChart;
