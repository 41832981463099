import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

import RemovedBackground from './Remove Bacgrkound -Final-login-image.png';

import { loginRequest } from '../../config/authConfig';

function LoginPage() {
  const { instance } = useMsal();

  const handleLogin = loginType => {
    if (loginType === 'popup' && instance) {
      instance.loginPopup(loginRequest).catch(e => {
        console.log(e);
      });
    }
  };

  return (
    <>
      <div className="loginPart">
        <div className="label-button-container">
          <div className="welcomeText">
            Welcome to the
            <p className="portal-name">Service Status Dashboard</p>
          </div>
          <Button
            className="login-button"
            onClick={() => handleLogin('popup')}
            data-testid="login-button"
          >
            Log in
          </Button>
        </div>
      </div>
      <div className="backgroundPicturePart">
        <img
          src={RemovedBackground}
          alt="emuPortalBackground"
          className="spinningTest"
        />
      </div>
    </>
  );
}

export default LoginPage;
