// store.js

import { configureStore } from '@reduxjs/toolkit';

import { userInfoReducer } from '../userPreferencesSlice.js';
const store = configureStore({
  reducer: {
    userInfo: userInfoReducer,
  },
});

export default store;
