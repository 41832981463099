// userInfoSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userInfo: null,
  status: 'idle', // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
  rawPreferences: [],
  apiCalled: false,
  hardRefresh: false,
  //   userDefaultThemeView: {
  //     "theme": {
  //       "primaryColor": "#ff0011",
  //       "secondaryColor": "#00ff00",
  //       "mode": "light"
  //     },
  //     "view": {
  //       "default": {
  //         "category_name": "CMP",
  //         "region": null,
  //         "csp": null,
  //         "device_type": null
  //       },
  //       "preferred": {
  //         "category_name": null,
  //         "region": null,
  //         "csp": null,
  //         "device_type": null,
  //         "exists": true
  //       }
  //     }
  //   }
};

const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,
  reducers: {
    // Define your actions here
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setRawPreferences: (state, action) => {
      state.rawPreferences = action.payload;
    },

    setUserDefaultThemeView: (state, action) => {
      state.userDefaultThemeView = action.payload;
      state.apiCalled = true;
    },

    setHardRefresh: (state, action) => {
      state.hardRefresh = !state.hardRefresh;
    },

    setTheme: (state, action) => {
      if (state.userDefaultThemeView) {
        state.userDefaultThemeView = {
          ...state.userDefaultThemeView,
          theme: { ...state.userDefaultThemeView.theme, mode: action.payload },
        };
      }
    },
  },
});

export const {
  setTheme,
  setHardRefresh,
  setUserInfo,
  setRawPreferences,
  setUserDefaultThemeView,
} = userInfoSlice.actions;

export const userInfoReducer = userInfoSlice.reducer;
