
import { Box, createFilterOptions } from '@mui/material';
import React from 'react';

export const filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: option => {
        const idValue = option?._id || option.serviceName || '';
        const ipValues = option?.data
            ?.map(item => item?.ip || '')
            ?.filter(Boolean);
        return `${idValue} ${ipValues?.join(' ')}`;
    },
});

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const MobileTabWithCount = ({ count }) => {
    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0px 10px 0px 0px',
            }}
        >
            <p className="navbarLabels">{count}</p>
        </Box>
    );
};

export const DesktopTabWithCount = ({ count, image, width, height }) => {
    return (
        <Box sx={{ display: 'inline-flex', alignItems: 'center', gap: '3px' }}>
            <img src={image} width={width} height={height} alt="CloudPlatformLogo" />
            <p className="navbarLabels">{count}</p>
        </Box>
    );
};
