import * as React from 'react';
import ReactQuill, { Quill } from 'react-quill';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/material';
import axios from 'axios';

import SaveButton from '../../SubscriptionModal/Mapping/Buttons/Save Button/saveButton';

import 'react-quill/dist/quill.snow.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#000',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
})); // Import the styles

export default function MajorAccomplishments({ data }) {
  // Custom toolbar configuration
  const modules = {
    toolbar: [
      [{ font: [] }, { size: ['small', false, 'large', 'huge'] }], // Font and size
      ['bold', 'italic', 'underline', 'strike'], // Toggle buttons
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }],
      ['link', 'image'],
      ['clean'], // Remove formatting button
      // [{ 'table': 'insert' }, { 'table': 'delete' }] // Custom table buttons
    ],
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'align',
    'link',
    'image',
    'table',
  ];
  const [loading, setLoading] = React.useState(true);
  const [editorContent, setEditorContent] = React.useState('');
  const [editorEnabled, setEditorEnabled] = React.useState(false);

  const updateAccomplishments = id => {
    axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/api/mic-reports/update/${id}`,
        { accomplishment: editorContent },
      )
      .then(() => {
        alert('updated successfully');
      })
      .catch(err => console.log(err));
  };

  React.useEffect(() => {
    // Simulate data fetching
    const fetchData = async data => {
      if (!data) {
        return;
      }
      const top_performers = data.top_performers;

      // Simulate a delay
      // setEditorContent(
      //   `<p>Platform Operations performers of the week (Request &amp; Incident) </p><p><br></p>
      //     <ul>
      //     ${top_performers.map((performer, index) => {
      //       return `<li key=${index}>Name: ${performer?.name} <br> Total Tickets Closed: ${performer?.total_tickets_closed}</li>`;
      //     })}
      //    </ul>
      //     `,
      // );

      setEditorContent(data.accomplishment);
      setLoading(false);
    };

    fetchData(data);
  }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    ); // Display a loading indicator
  }

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <h4 style={{ color: '#046a38' }}>Major Accomplishments </h4>

        <Stack direction="row" gap={1}>
          {editorEnabled && (
            <SaveButton
              mobileStyle={{
                fontSize: '12px',
                color: 'white',
                width: '120px',
                backgroundColor: '#046a38',
              }}
              handleSave={() => updateAccomplishments(data?._id)}
              text={'Update'}
            ></SaveButton>
          )}
          <SaveButton
            mobileStyle={{
              fontSize: '12px',
              color: 'white',
              width: '180px',
              backgroundColor: '#046a38',
            }}
            handleSave={() => setEditorEnabled(!editorEnabled)}
            text={editorEnabled ? 'Disable Editor' : 'Enable Editor'}
          ></SaveButton>
        </Stack>
      </div>
      {editorEnabled ? (
        <ReactQuill
          value={editorContent}
          onChange={setEditorContent}
          modules={modules}
          formats={formats}
          theme="snow"
        />
      ) : (
        <div dangerouslySetInnerHTML={{ __html: editorContent }} />
      )}
    </div>
  );
}
