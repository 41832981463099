import { api } from '../axiosWrapper';

export const userInfoFetch = async emailId => {
  const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/others/findUserPreferences`;
  try {
    // Make an authenticated POST API call using the access token obtained from react-msal.
    const response = await api.post(API_ENDPOINT, { email: emailId });
    const { data } = response || {};
    const userInfo = data;
    return userInfo;
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching user preferences:', error);
    throw error;
  }
};

export const userThemeDefaultViewFetch = async emailId => {
  const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/user-preferences/${emailId}`;
  try {
    // Make an authenticated POST API call using the access token obtained from react-msal.
    const response = await api.get(API_ENDPOINT);
    const { data } = response || {};
    const { ui_preferences } = data;
    return ui_preferences;
  } catch (error) {
    // Handle any errors here
    console.error('Error fetching user preferences:', error);
    throw error;
  }
};

export const userThemeDefaultViewUpdate = async (emailId, updatedPreferences) => {
  const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/user-preferences/update`;
  try {
    const updatedPreferencesWithId = { ...updatedPreferences, email: emailId };
    // Make an authenticated POST API call using the access token obtained from react-msal.
    const response = await api.post(API_ENDPOINT, updatedPreferencesWithId);
    const { data } = response || {};
    return data;
  } catch (error) {
    // Handle any errors here
    console.error('Error updating user preferences:', error);
    throw error;
  }
};
