import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SettingsIcon from '@mui/icons-material/Settings';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import LegendList from '../../components/LegendList/LegendList';
import SubscriptionModal from '../../components/SubscriptionModal/SubscriptionModal';
import UserPreferences from '../../components/UserPreferences/UserPreferences';
import BellIcon from '../../ImagesMisc/Bell-Icon.png';
import error from '../../info-logos/Error-display.png';
import success from '../../info-logos/Success-display.png';
import warning from '../../info-logos/Warning-display.png';
import Joyride, { STATUS } from 'react-joyride';

import '../../LoaderSpinner/loaderspinner.css';
import './insights.css';

function Insights(props) {
  const { rawPreferences } = useSelector(state => state.userInfo) || {};
  const [alerts, setAlerts] = useState(['']);
  const [loading, setLoading] = useState(true);
  const [expandAlerts, setExpandAlerts] = useState(false);
  const fetchAlertsUrl = process.env.REACT_APP_BACKEND_URL;
  const [openModal, setOpenModal] = useState(false);
  const [preferenceLoading, setPreferenceLoading] = useState(true);
  const userCustomDefaultView = useSelector(state => state.userInfo) || {};
  const { userDefaultThemeView } = userCustomDefaultView || {};
  const { userInfo } = useSelector(state => state.userInfo) || {};
  const { username } = userInfo || {};
  const [runTour, setRunTour] = useState(false);
  const [isUserPreferenceModalOpen, setIsUserPreferenceModalOpen] =
    useState(false);

  const handleCloseUserPreferenceModal = () => {
    setIsUserPreferenceModalOpen(false);
  };

  useEffect(() => {
    if (rawPreferences.length > 0) {
      setPreferenceLoading(false);
    } else {
      setTimeout(() => {
        setPreferenceLoading(false);
      }, 2000);
    }
  }, [rawPreferences]);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleClickBtn = () => {
    setExpandAlerts(prev => !prev);
  };

  const fetchAlertsData = async () => {
    axios
      .get(`${fetchAlertsUrl}/api/others/alerts`)
      .then(res => {
        if (res) {
          setLoading(false);
          setAlerts(res.data);
        }
      })
      .catch(err => { });
  };

  useEffect(() => {
    fetchAlertsData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAlertsData();
    }, 90000);

    //Clearing the interval
    return () => clearInterval(interval);
  }, [alerts]);

  // useEffect(() => {
  //   if (!preferenceLoading) {
  //     if (userDefaultThemeView?.view?.preferred?.exists === false) {
  //       setTimeout(() => {
  //         toast.success(`Hey ${username}! Looks like you haven't set any preferences. Please do so by clicking the settings icon.`, {
  //           icon: '⚙️',
  //           duration: 4000 // 4-second duration
  //         });
  //       }, 4000); // 3-second delay before showing the toast
  //     }
  //   }
  // }, [preferenceLoading, userDefaultThemeView]);

  useEffect(() => {
    if (!preferenceLoading && userDefaultThemeView?.view?.preferred?.exists === false && userInfo?.username) {
      setTimeout(() => {
        setRunTour(true);
      }, 4000); // 3-second delay before starting the tour
    }
  }, [preferenceLoading, userDefaultThemeView]);

  const renderSwitch = param => {
    return param <= 0 ? error : param >= 1 && param <= 99.9 ? warning : success;
  };

  const cloudNetworkMapper = param => {
    return param === 0 ? error : param < 1 ? warning : success;
  };

  //React Joyride Tour Steps

  const tourSteps = [
    {
      target: '#settings-icon',
      content: (
        <div>
          Hey there {username}! <br />
          Looks like you haven&#39;t set any preferences. <br />
          Please click the settings icon to set your theme and default view.
        </div>
      ),
      disableBeacon: true,
      placement: 'bottom',
    },
  ];

  return (
    <>
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        styles={{
          options: {
            zIndex: 10000,
          },

          tooltipContent: {
            padding: '10px 0', // Adjust padding as needed
          },
          buttonNext: {
            marginTop: '0',
            backgroundColor: '#86bc25',
            color: 'white',
          },
          tooltipFooter: {
            marginTop: '0', // Remove margin-top
          },

        }
        }
        locale={{
          last: 'Close',// Text on the last button
        }}
        callback={(data) => {
          const { status } = data;
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
          }
        }}
      />
      <SubscriptionModal
        open={openModal}
        handleClose={handleCloseModal}
      // usersName={usersData}
      />

      <div className="insights-container">
        <div className="insight-flex">
          <p className="service-insight-label-new">Service Health Insights</p>{' '}
          <div
            style={{
              display: 'flex',
              fleDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              className="updates-btn subscribe-button"
              onClick={handleOpenModal}
              disabled={preferenceLoading}
            >
              {!preferenceLoading && (
                <img
                  src={BellIcon}
                  alt="bellicon"
                  className="bell-image-icon"
                />
              )}

              <p className="updates-txt ">
                {preferenceLoading
                  ? ' Fetching preferences . . .'
                  : window.screen.width > 1304
                    ? 'Subscribe to Updates'
                    : 'Subscribe'}
              </p>
            </Button>

            <div title="Open Preferences">
              <SettingsIcon
                id="settings-icon"
                className={`spin-icon`}
                onClick={() => setIsUserPreferenceModalOpen(true)}
                data-tip="Click here to set your preferences"
              />
              {isUserPreferenceModalOpen && (
                <UserPreferences
                  open={isUserPreferenceModalOpen}
                  onClose={handleCloseUserPreferenceModal}
                />
              )}
            </div>
          </div>
        </div>
        {!loading && alerts.length > 0 && (
          <div className="services-affected-container">
            <p style={{ fontSize: '18px' }}>
              Current Products / Services affected{' '}
            </p>
          </div>
        )}
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <>
            {' '}
            <div className="services-affected-container">
              {alerts.length > 0 && !loading ? (
                <div
                  className="alerts-container"
                  style={{
                    overflowY: `${alerts.length >= 4 ? 'scroll' : 'none'}`,
                  }}
                >
                  <div
                    className="card-alert-container"
                    style={{
                      maxHeight: expandAlerts ? '1000px' : '15rem',
                    }}
                  >
                    {alerts.map((alert, index) => (
                      <Stack
                        direction="row"
                        key={index}
                        className="insight-flex-alert"
                        style={{ width: '100%' }}
                      >
                        <Stack
                          direction="row"
                          className="stack-space-alerts"
                          style={{
                            width: '100%',
                            flexWrap: 'wrap',
                          }}
                        >
                          <div
                            className="info-name"
                            style={{
                              width: '30%',
                            }}
                          >
                            <img
                              src={
                                alert?.category === 'cloudNetwork'
                                  ? cloudNetworkMapper(alert?.availability)
                                  : renderSwitch(alert?.availability)
                              }
                              className="info-img-display"
                              alt="error"
                            ></img>
                            <p className="alert-name-card">
                              {' '}
                              {alert?.product_name || alert?.publicName}
                            </p>
                          </div>
                          <div
                            className="info-description"
                            style={{
                              width: '40%',
                            }}
                          >
                            {' '}
                            <p
                              className="alert-name-card"
                              title={alert?.alertMessage}
                            >
                              {alert?.alertMessage}
                            </p>
                          </div>
                          <div
                            className="info-description"
                            style={{
                              width: '15%',
                            }}
                          >
                            {' '}
                            <p className="alert-name-card">
                              {alert.category === 'cloudNetwork'
                                ? alert?.availability?.toFixed(2) * 100
                                : alert?.availability?.toFixed(2)}{' '}
                              %
                            </p>
                          </div>
                          <div
                            className="info-timestamp"
                            style={{
                              width: '15%',
                            }}
                          >
                            {' '}
                            <p className="time-stamp-label">{alert?.ISODate}</p>
                          </div>
                        </Stack>
                      </Stack>
                    ))}
                  </div>
                </div>
              ) : (
                <div className="card-alert-container">
                  <Stack direction="row" className="success-flex-alert">
                    <div className="info-description">
                      {' '}
                      <p className="operational-text">
                        All Services are Operational today
                      </p>
                    </div>
                  </Stack>
                </div>
              )}
            </div>
          </>
        )}
        {alerts.length > 5 && loading === false ? (
          <div className="alert-expand" onClick={handleClickBtn}>
            <p>{expandAlerts ? 'Collapse' : 'Expand'}</p>
            <ExpandLessIcon
              style={{
                transform: expandAlerts ? 'rotate(360deg)' : 'rotate(180deg)',
              }}
            />
          </div>
        ) : null}

        <LegendList />
      </div>
    </>
  );
}

export default Insights;
