export const mockMICData = {
  Last_Week_Data: {
    incidents: {
      open: 134,
      closed: 117,
    },
    requests: {
      open: 205,
      closed: 239,
    },
    mic_handled: 10,
    week_of: "September 7 '24 - September 13 '24",
    All_Member_Firms: [
      //   {
      //     "member_firm": "Total",
      //     "requested_item_count": 193,
      //     "percentage_of_count": 200
      //   },
      {
        member_firm: 'Deloitte United States',
        mf_code: 'US',
        requested_item_count: 125,
        percentage_of_count: 103.21,
      },
      {
        member_firm: 'Deloitte Touche Tohmatsu Limited',
        mf_code: 'DTTL',
        requested_item_count: 30,
        percentage_of_count: 33.58,
      },
      {
        member_firm: 'Deloitte Asia Pacific',
        mf_code: 'Asia Pacific',
        requested_item_count: 18,
        percentage_of_count: 26.35,
      },
      {
        member_firm: 'Deloitte Japan',
        mf_code: 'Japan',
        requested_item_count: 12,
        percentage_of_count: 22.74,
      },
      {
        member_firm: 'Deloitte Canada',
        mf_code: 'Canada',
        requested_item_count: 5,
        percentage_of_count: 3.01,
      },
    ],
    SLA_Breached_Data: {
      requests_breached: [
        {
          Has_breached: 'false',
          Catalog_Request_Item_SLA_Count: '222',
          Percentage_of_Catalog_Request_Item_SLAs: '96.1%',
        },
        {
          Has_breached: 'true',
          Catalog_Request_Item_SLA_Count: '9',
          Percentage_of_Catalog_Request_Item_SLAs: '3.9%',
        },
      ],
      incidents_breached: [
        {
          Ticket_SLA_Breach: 'false',
          Incident_Count: '135',
          Percentage_of_Incidents: '99.26%',
        },
        {
          Ticket_SLA_Breach: 'true',
          Incident_Count: '1',
          Percentage_of_Incidents: '0.74%',
        },
      ],
    },
    CSP_Data: {
      top_performers: [
        {
          Name: 'Kattegummula Shravan Reddy',
          total_tickets_closed: 30,
        },
        {
          Name: 'Pramila Roseline',
          total_tickets_closed: 58,
        },
      ],
      csp_wise_data: [
        { name: 'AWS', Requests: 34, Incidents: 35 },
        { name: 'Azure', Requests: 74, Incidents: 7 },
        { name: 'GCP', Requests: 21, Incidents: 3 },
        { name: 'VDI', Requests: 20, Incidents: 19 },
        { name: 'Others', Requests: 1, Incidents: 3 },
      ],
    },
  },
};
