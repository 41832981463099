import * as React from 'react';
import { useEffect, useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MenuItem, Select } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import CustomTabs from './CustomTabs';

import { api } from '../../../axiosWrapper';
import Aws from '../../../CloudPlatform_Images/aws.png';
import Azure from '../../../CloudPlatform_Images/azure.png';
import Cmp from '../../../CloudPlatform_Images/cmp.png';
import Gcp from '../../../CloudPlatform_Images/gcp.png';
import AWSHealthDashboard from '../../AWShealthDashboard/awsHealthDashboard';
import TableHealth from '../../HealthCard/HealthCard';
import { StyledPopper } from '../../SubscriptionModal/utils/CustomAutocomplete/customAutocomplete';

import './navbar.css';
import '../../../LoaderSpinner/loaderspinner.css';
import {
  awsRegions,
  awsRegionsMapper,
} from '../NavbarRegionMappings/awsRegions';
import {
  azureRegions,
  azureRegionMapper,
} from '../NavbarRegionMappings/azureRegions';
import {
  categoryMapper,
  deviceTypeMapper,
  mapCategoryToIndex,
  regions,
  regionsMapper,
} from '../NavbarRegionMappings/generalRegionMapper';
import {
  gcpRegions,
  gcpRegionsMapper,
} from '../NavbarRegionMappings/gcpRegions';
import {
  a11yProps,
  DesktopTabWithCount,
  filterOptions,
} from '../TabPanel/TabPanel';
import { setHardRefresh } from '../../../redux/userPreferencesSlice';
import { useDispatch, useSelector } from 'react-redux';

export default function NavBar() {
  const mockDefaulltUserPreferences = {
    theme: {
      primaryColor: null,
      secondaryColor: null,
      mode: 'dark',
    },
    view: {
      default: {
        category_name: 'CMP',
        region: null,
        csp: null,
        device_type: null,
      },
      preferred: {
        exists: false,
        category_name: null,
        region: null,
        csp: null,
        device_type: null,
      },
    },
  };

  const mockCMPUserPreferences = {
    theme: {
      primaryColor: null,
      secondaryColor: null,
      mode: 'dark',
    },
    view: {
      default: {
        category_name: 'CMP',
        region: null,
        csp: null,
        device_type: null,
      },
      preferred: {
        exists: false,
        category_name: null,
        region: null,
        csp: null,
        device_type: null,
      },
    },
  };

  const mockCloudNetworkUserPreferences = {
    theme: {
      primaryColor: null,
      secondaryColor: null,
      mode: 'dark',
    },
    view: {
      default: {
        category_name: 'CMP',
        region: null,
        csp: null,
        device_type: null,
      },
      preferred: {
        exists: true,
        category_name: 'Cloud Network',
        region: 'Asia Pacific',
        csp: 'Azure',
        device_type: 'F5',
      },
    },
  };

  const mockCSPUserPreferences = {
    theme: {
      primaryColor: null,
      secondaryColor: null,
      mode: 'dark',
    },
    view: {
      default: {
        category_name: 'CMP',
        region: null,
        csp: null,
        device_type: null,
      },
      preferred: {
        exists: true,
        category_name: 'Azure',
        region: 'asia_pacific',
        csp: null,
        device_type: null,
      },
    },
  };

  const dispatch = useDispatch();
  const { hardRefresh, userDefaultThemeView, apiCalled } =
    useSelector(state => state.userInfo) || {};
  const [value, setValue] = React.useState(0);
  const [apiData, setApiData] = useState({ data: [], empty: 'false' });
  const [regionActive, setRegionActive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState('');
  const [csp, setCSP] = useState('Azure');
  const [cachedApiData, setCachedApiData] = useState([]);
  const [searchLabelText, setSearchLabelText] = useState('');
  const [categorySelector, setCategorySelector] = useState(
    deviceTypeMapper[0].value,
  );

  const [awsSelectedRegion, setAwsSelectedRegion] = useState('global');
  const [gcpSelectedRegion, setGcpSelectedRegion] = useState('global');
  const [selectedOption, setSelectedOption] = useState(null);
  //Triggers for user default view
  const [automaticTriggerHelperFlag, setAutomaticTriggerHelperFlag] =
    useState(false);
  const [automaticTrigger, setAutomaticTrigger] = useState(false);

  const serverUrl = process.env.REACT_APP_BACKEND_URL;
  const fetchData = async value => {
    setApiData([]);
    setLoading(true);

    try {
      let endpoint = '';

      switch (value) {
        case 0:
          endpoint = `/api/${categoryMapper[value]}`;
          break;
        case 1:
          endpoint = `/api/cloud/${categorySelector}/${regionsMapper[regionActive]}?csp=${csp}`;
          break;
        case 2:
          endpoint = `/api/azure/${azureRegionMapper[regionActive]}`;
          break;
        case 3:
          endpoint = `/api/aws/${awsSelectedRegion}`;
          break;
        case 4:
          endpoint = `/api/gcp/${gcpSelectedRegion}`;
          break;
        default:
          endpoint = `/api/others/${categoryMapper[value]}`;
      }

      const res = await api.get(`${serverUrl}${endpoint}`);

      if (res.data && res.data.length > 0) {
        if (value === 0) {
          const result = res.data.sort((a, b) => a._id.localeCompare(b._id));
          setApiData({ data: result, empty: 'false' });

          setCachedApiData({ data: result, empty: 'false' });
        } else {
          setApiData({ data: res.data, empty: 'false' });
          setCachedApiData({ data: res.data, empty: 'false' });
        }
      } else {
        setApiData({ data: [], empty: 'true' });
      }
    } catch (error) {
      // Handle error
    } finally {
      setLoading(false);
      setAutomaticTriggerHelperFlag(false);
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const setRegionActiveButton = index => {
    setRegionActive(index);
  };

  const cspOptions = [
    { label: 'Azure', value: 'Azure' },
    { label: 'AWS', value: 'AWS' },
    { label: 'GCP', value: 'GCP' },
  ];

  const handleSearchChange = (e, v) => {
    setSearchString(v?._id || v?.serviceName || '');
  };

  const mapRegionToIndex = (region, category) => {
    const awsRegionMap = {
      global: 0,
      north_america: 1,
      south_america: 2,
      europe: 3,
      africa: 4,
      asia_pacific: 5,
      middle_east: 6,
    };

    const cloudNetworkRegionMap = {
      Americas: 0,
      Europe: 1,
      'Asia Pacific': 2,
    };

    const azureRegionMap = {
      americas: 0,
      europe: 1,
      asia_pacific: 2,
    };

    const gcpRegionMap = {
      global: 0,
      americas: 1,
      europe: 2,
      africa: 3,
      asia_pacific: 4,
      middle_east: 5,
    };

    switch (category) {
      case 'AWS':
        return awsRegionMap[region];
      case 'AZURE':
        return azureRegionMap[region];
      case 'GCP':
        return gcpRegionMap[region];
      case 'Cloud Network':
        return cloudNetworkRegionMap[region];
      default:
        return null;
    }
  };

  const handlePreferredView = preferred => {
    const categoryIndex = mapCategoryToIndex[preferred.category_name];
    setAutomaticTrigger(true);
    setAutomaticTriggerHelperFlag(true);
    setValue(categoryIndex);

    switch (preferred.category_name) {
      case 'Cloud Network':
        setRegionActive(mapRegionToIndex(preferred.region, 'Cloud Network'));
        setCategorySelector(preferred.device_type);
        setCSP(preferred.csp);
        break;
      case 'AWS':
        handleAWSView(preferred);
        break;
      case 'AZURE':
        setRegionActive(mapRegionToIndex(preferred.region, 'AZURE'));
        break;
      case 'GCP':
        setGcpSelectedRegion(preferred.region);
        setRegionActive(mapRegionToIndex(preferred.region, 'GCP'));
        break;
      default:
        break;
    }
  };

  const handleAWSView = preferred => {
    const regionIndex = mapRegionToIndex(preferred.region, 'AWS');
    if (awsSelectedRegion !== preferred.region) {
      setAwsSelectedRegion(preferred.region);
    }
    if (regionActive !== regionIndex) {
      setRegionActive(regionIndex);
    }
  };

  useEffect(() => {
    if (hardRefresh) {
      handlePreferredView(userDefaultThemeView.view.preferred);
      dispatch(setHardRefresh());
    }
  }, [userDefaultThemeView, hardRefresh]);

  useEffect(() => {
    if (searchString === '' || !searchString) {
      setApiData(cachedApiData);
    } else {
      const result = cachedApiData.data.filter(item => {
        return (
          item?._id?.toLowerCase() || item.serviceName.toLowerCase()
        ).includes(searchString.toLowerCase());
      });
      setApiData({ data: result, empty: 'false' });
    }
  }, [searchString]);

  useEffect(() => {
    setSearchString('');
    setSelectedOption(null);
  }, [
    value,
    regionActive,
    awsSelectedRegion,
    gcpSelectedRegion,
    categorySelector,
    csp,
  ]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData(value);
    }, 90000);
    return () => clearInterval(interval);
  }, [value, regionActive]);

  useEffect(() => {
    if (!automaticTriggerHelperFlag) {
      setGcpSelectedRegion('global');
      setAwsSelectedRegion('global');
      setRegionActive(0);
      setSearchString('');
      fetchData(value);
    } else {
      setAutomaticTriggerHelperFlag(false);
      fetchData(value);
    }
  }, [value]);

  useEffect(() => {
    switch (value) {
      case 0:
        setSearchLabelText('Search CMP Products');
        break;
      case 1:
        setSearchLabelText(
          `Search ${categorySelector === 'F5' ? 'F5' : 'Palo Alto'
          } Devices by IP or Name`,
        );
        break;
      case 2:
        setSearchLabelText('Search Azure Services');
        break;
      case 3:
        setSearchLabelText('Search AWS Services');
        break;
      case 4:
        setSearchLabelText('Search GCP Services');
        break;
      default:
        setSearchLabelText('Search Services');
    }
  }, [value, categorySelector]);

  useEffect(() => {
    if (automaticTrigger && !automaticTriggerHelperFlag) {
      fetchData(value);
    } else {
      //console.log("Not triggering, automaticTrigger", automaticTrigger, "automaticTriggerHelperFlag", automaticTriggerHelperFlag)
    }
  }, [
    categorySelector,
    csp,
    awsSelectedRegion,
    gcpSelectedRegion,
    regionActive,
  ]);

  useEffect(() => {
    setSearchString('');
    if (apiCalled && !automaticTrigger) {
      if (userDefaultThemeView?.view) {
        const { view } = userDefaultThemeView;
        if (view) {
          const { preferred } = view;

          if (preferred.exists) {
            handlePreferredView(preferred);
          } else {
            setAutomaticTrigger(true);
            setAutomaticTriggerHelperFlag(true);
          }
        }
      } else {
        setAutomaticTrigger(true);
        setAutomaticTriggerHelperFlag(true);
      }
    }
  }, [value, userDefaultThemeView, apiCalled]);

  return (
    <Box sx={{ width: '100%' }} className="service-insight-label">
      <Box>
        <Box
          component="span"
          m={1}
          pt={4}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Tabs
            TabIndicatorProps={{
              style: { background: '#00A3E0', height: '4px' },
            }}
            sx={{ paddingLeft: '5px' }}
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              label={
                <DesktopTabWithCount
                  count="CMP"
                  image={Cmp}
                  width="54px"
                  height="40px"
                ></DesktopTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <DesktopTabWithCount
                  count="Cloud Network"
                  image={Cmp}
                  width="54px"
                  height="40px"
                ></DesktopTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <DesktopTabWithCount
                  count="Azure"
                  image={Azure}
                  width="48px"
                  height="30px"
                >
                  Azure
                </DesktopTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <DesktopTabWithCount
                  count="AWS"
                  image={Aws}
                  width="44px"
                  height="30px"
                >
                  AWS
                </DesktopTabWithCount>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <DesktopTabWithCount
                  count="GCP"
                  image={Gcp}
                  width="34px"
                  height="30px"
                ></DesktopTabWithCount>
              }
              {...a11yProps(0)}
            />
          </Tabs>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Autocomplete
              disablePortal
              PopperComponent={StyledPopper} // Use the styled Popper component
              key={value}
              className="search_bar"
              filterOptions={filterOptions}
              id="combo-box-demo"
              getOptionLabel={healthData =>
                healthData._id || healthData.serviceName || ''
              }
              sx={{
                '.MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  padding: '0px',
                  border: '1px solid var(--search_bar_outline)',
                },
                width: 300,
                color: 'var(--hour_color)',
                '.MuiAutocomplete-inputRoot': {
                  display: 'flex',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  paddingRight: '30px !important', // Adjust padding to create space for the "x" mark
                },
              }}
              onChange={(e, v) => {
                setSelectedOption(v); // Update the selected option state
                handleSearchChange(e, v); // Call your existing change handler
              }}
              value={selectedOption}
              options={apiData.data || []}
              renderInput={params => (
                <TextField
                  className="autocomplete-search-text-field"
                  {...params}
                  label={searchLabelText}
                  sx={{
                    color: 'var(--hour_color)',
                    '& .MuiInputLabel-root': {
                      top: '-7px',
                      color: 'var(--search_service_label_color)',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'var(--search_service_label_color)',
                    },
                    '.MuiInputBase-input': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      paddingRight: '30px !important', // Ensure this matches the padding added to the input root
                    },
                  }}
                />
              )}
            />

            <Tooltip
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              fontSize="medium"
              placement="top"
              title="This is an AutoComplete Searchbox. Start typing to get suggestions"
            >
              <span>
                <InfoOutlinedIcon className="info-icon-alerts-modal" />
              </span>
            </Tooltip>
          </div>
        </Box>

        <>
          {[3].includes(value) ? (
            <CustomTabs
              regions={awsRegions}
              active={regionActive}
              onClick={index => {
                setAwsSelectedRegion(awsRegionsMapper[index]);
                setRegionActive(index);
              }}
            />
          ) : [4].includes(value) ? (
            <CustomTabs
              regions={gcpRegions}
              active={regionActive}
              onClick={index => {
                setGcpSelectedRegion(gcpRegionsMapper[index]);
                setRegionActive(index);
              }}
            />
          ) : (
            <div className="region-dropdown-flex">
              <div style={{ display: 'flex' }}>
                {regions.map(
                  (region, index) =>
                    [1, 2].includes(value) && (
                      <div key={index} className="aws-btn-containers">
                        <button
                          className={
                            regionActive === index
                              ? 'btn-aws btn-aws-active'
                              : 'btn-aws btn-aws-inactive'
                          }
                          onClick={() => {
                            setRegionActiveButton(index);
                          }}
                        >
                          {region.region}
                        </button>
                      </div>
                    ),
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'baseline',
                  marginTop: ' -18px',
                }}
              >
                {value === 1 && (
                  <>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginRight: '10px',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          marginBottom: '0px',
                        }}
                      >
                        Select Device &nbsp;&nbsp;
                      </p>
                      <Select
                        className="device-selection"
                        MenuProps={{
                          MenuListProps: {
                            style: {
                              backgroundColor: 'var(--search_bar_dark_bg)',
                              color: 'var(--body_color)',
                            },
                          },
                        }}
                        onChange={e => {
                          setCategorySelector(e.target.value);
                        }}
                        value={categorySelector}
                      >
                        {deviceTypeMapper.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: '12px',
                          marginBottom: '0px',
                        }}
                      >
                        CSP &nbsp;&nbsp;
                      </p>
                      <Select
                        className="device-selection"
                        MenuProps={{
                          MenuListProps: {
                            style: {
                              backgroundColor: 'var(--search_bar_dark_bg)',
                              color: 'var(--body_color)',
                            },
                          },
                        }}
                        onChange={e => {
                          setCSP(e.target.value);
                        }}
                        value={csp}
                      >
                        {cspOptions.map((e, i) => (
                          <MenuItem key={i} value={e.value}>
                            {e.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      </Box>
      {loading ? (
        <div className="table-loader-container">
          <div className="loader" data-testid="navbar-loader" />
        </div>
      ) : [2].includes(value) || [3].includes(value) || [4].includes(value) ? (
        <AWSHealthDashboard apiData={apiData} loading={loading} />
      ) : (
        <TableHealth apiData={apiData} category={value} />
      )}
    </Box>
  );
}
