import React, { useEffect, useState } from 'react';
import { Typography, Box, TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Button } from 'bootstrap';
import SaveButton from '../../components/SubscriptionModal/Mapping/Buttons/Save Button/saveButton';
import { Link } from 'react-router-dom';

function getWeekRange(startDate, endDate) {
  let options = { year: 'numeric', month: 'long', day: 'numeric' };
  let startString = startDate
    ? startDate.format('MMMM D, YYYY')
    : 'Select start date';
  let endString = endDate ? endDate.format('MMMM D, YYYY') : 'Select end date';

  return `Week of ${startString} to ${endString}`;
}

export default function RangeCalendar() {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs().endOf('week'));
  useEffect(() => {
    setEndDate(dayjs(startDate).endOf('week'));
  }, [startDate]);

  //   useEffect(() => {
  //     setStartDate(dayjs(endDate).startOf('week'));
  //   }, [endDate]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography
        variant="h5"
        component="h1"
        gutterBottom
        align="center"
        style={{ color: '#86BC25', marginTop: '20px' }}
      >
        {getWeekRange(startDate, endDate)}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 2,
          mt: 4,
          padding: '1rem',
        }}
      >
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={newDate => setStartDate(newDate)}
          renderInput={params => <TextField {...params} />}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={newDate => setEndDate(newDate)}
          renderInput={params => <TextField {...params} />}
        />

        <Link to={`/historical-report/${startDate}/${endDate}`}>Fetch</Link>
      </Box>
    </LocalizationProvider>
  );
}
