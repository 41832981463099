import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { Stack } from '@mui/material';

import error from '../../info-logos/Error-display.png';
import success from '../../info-logos/Success-display.png';
import warning from '../../info-logos/Warning-display.png';

import './InstructionModal.css';

const InstructionModal = ({ alerts }) => {
  const renderSwitch = param => {
    return param <= 0 ? error : param >= 1 && param <= 99.9 ? warning : success;
  };

  const cloudNetworkMapper = param => {
    return param === 0 ? error : param < 1 ? warning : success;
  };

  const Item = ({ item, index }) => {
    const serviceName = item?.product_name || item?.publicName;
    return (
      <div className="card-alert-container">
        <Stack
          direction="row"
          key={index}
          className="insight-flex-alert"
          style={{ width: '100%' }}
        >
          <Stack
            direction="column"
            style={{
              width: '100%',
              flexWrap: 'wrap',
            }}
          >
            <Stack
              direction="row"
              className="stack-space-alerts"
              style={{
                width: '100%',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <div
                className="info-name"
                style={{ width: '16%', textAlign: 'right' }}
              >
                <img
                  src={
                    item?.category === 'cloudNetwork'
                      ? cloudNetworkMapper(item?.availability)
                      : renderSwitch(item?.availability)
                  }
                  className="info-img-display"
                  alt="error"
                ></img>
              </div>
              <div
                className="info-description info-name-carousel"
                style={{
                  width: '67%',
                  textAlign: 'right',
                }}
              >
                {' '}
                <p className="alert-name-card">
                  {serviceName?.length > 35
                    ? serviceName.slice(0, 35) + '...'
                    : serviceName}
                  {/* {item?.product_name} */}
                </p>
              </div>

              {/* availability % */}
              <div
                className="info-description info-availability-carousel"
                style={{
                  width: '15%',
                  textAlign: 'right',
                }}
              >
                {' '}
                <p className="alert-name-card">
                  {item.category === 'cloudNetwork'
                    ? item?.availability?.toFixed(2) * 100
                    : item?.availability?.toFixed(2)}{' '}
                  %
                </p>
              </div>
              {/* timestamp */}
              <div className="info-timestamp">
                {' '}
                <p className="time-stamp-label">{item?.ISODate}</p>
              </div>
            </Stack>
          </Stack>
        </Stack>
      </div>
    );
  };

  return (
    <div>
      <Carousel
        sx={{ minHeight: 'fit-content' }}
        animation="slide"
        fullHeightHover={false}
        swipe={true}
        cycleNavigation={true}
        duration={1000}
        interval={8000}
        indicators={true}
        navButtonsAlwaysInvisible={true}
        navButtonsProps={{
          style: {
            backgroundColor: 'unset',
            borderRadius: 0,
            opacity: 'unset',
          },
        }}
        navButtonsWrapperProps={{
          style: {
            height: 'unset',
            position: 'absolute',
            bottom: '0px',
            top: 'unset',
          },
        }}
        indicatorContainerProps={{
          style: {
            position: 'relative',
            top: 'unset',
            bottom: '0px',
          },
        }}
        indicatorIconButtonProps={{
          style: {
            padding: '3px',
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: 'rgb(11 153 211)',
          },
        }}
        navButtonsAlwaysVisible={false}
        autoPlay={true}
      >
        {alerts?.map((item, i) => (
          <Item key={i} index={i} item={item} />
        ))}
      </Carousel>
    </div>
  );
};

export default InstructionModal;
