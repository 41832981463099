import React from 'react';
import { useMsal } from '@azure/msal-react';
import { Button } from '@mui/material';

import './signInout.css';

/**
 * Renders a button which, when selected, will open a popup for logout
 */
export const SignOutButton = () => {
  const { instance } = useMsal();

  const handleLogout = logoutType => {
    if (logoutType === 'popup') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/insights',
      });
    }
  };

  return (
    <Button className="sign-out-button" onClick={() => handleLogout('popup')}>
      Sign out
    </Button>
  );
};
