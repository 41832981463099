import React from 'react';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import './mobileSubscriptionModal.css';
function MobileWelcomeContainer({ onClose }) {
  const { userInfo } = useSelector(state => state.userInfo) || {};
  const { username } = userInfo || {};
  return (
    <div className="subscription-modal-welcome-msg-container">
      <Typography
        id="modal-modal-description"
        sx={{ fontSize: '16px', fontWeight: '300' }}
      >
        {`Hey ${
          username ? username : 'User'
        }, please choose Products / Services you wish to
receive alerts for`}
      </Typography>

      {/* Close Icon */}
      <CloseIcon
        data-testid="close-icon"
        onClick={onClose}
        style={{ cursor: 'pointer' }}
      />
    </div>
  );
}

export default MobileWelcomeContainer;
