import React from 'react';
import successGIF from './success.gif';
import { userThemeDefaultViewUpdate } from '../../../redux/userPreferenceFetch';
import toast from 'react-hot-toast';

export const handleDefaultViewUpdate = async (
  currentUser,
  updatedPreferences
) => {
  try {
    // Show a loading toast
    const loadingToast = toast.loading('Updating your UI preferences', {
      style: {
        minWidth: '300px',
      },
    });

    const updatedResponse = await userThemeDefaultViewUpdate(currentUser, updatedPreferences);

    // Delay for 1 second (1000 milliseconds) for better UI perspective
    await new Promise(resolve => setTimeout(resolve, 1000));

      // Dismiss the loading toast after the artificial delay
      toast.dismiss(loadingToast);

    if (updatedResponse?.success) {
      // Show a success toast when the update was successful
      toast(
        <div>
          <img
            src={successGIF}
            height={'40px'}
            width={'40px'}
            alt="Success Icon"
          />
          {updatedResponse.msg}
        </div>,
        {
          duration: 5000,
          style: {
            minWidth: 'fit-content',
          },
        },
      );
    } else {
      // Show an error toast if the update was not successful
      toast.error( updatedResponse?.msg ||
        'Failed to update UI preferences. Please try again.',
        {
          duration: 6000,
          style: {
            minWidth: 'fit-content',
          },
        },
      );
    }

    // Return the updated preferences
    return updatedResponse;
  } catch (err) {
    // Show an error toast if there was an error with the API request
    toast.error(`An error occurred: ${err.toString()}`, {
      style: {
        minWidth: '300px',
      },
    });
  }
};
