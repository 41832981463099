import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';

import { msalConfig } from './config/authConfig';
import store from './redux/store/store.js';
import App from './App';

import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
const msalInstance = new PublicClientApplication(msalConfig);
const container = document.querySelector('#root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App msalInstance={msalInstance} data-testid="app" />
  </Provider>,
);
