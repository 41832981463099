import React from 'react';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Stack } from '@mui/material';
import Fade from '@mui/material/Fade';
import Tooltip from '@mui/material/Tooltip';

import logo from './header-logo.svg';

import StringAvatar from '../Avatar/stringAvatar';
import { SignOutButton } from '../Login/SignOutButton';

import './header.css';

function Header() {
  const isAuthenticated = useIsAuthenticated();
  const { accounts } = useMsal();
  return (
    <nav className="Navbar-HeaderD">
      <a href="https://www2.deloitte.com" className="link-a">
        <img alt="Deloitte" src={logo} className="image-logo"></img>
      </a>
      <span className="span-logo">
        <div className="CMP-label">DT - Cloud Management Platform</div>
      </span>
      <span className="search-name">
        <span className="input-search-name-box"></span>
      </span>

      <div height="40" width="40" className="name-flex">
        <Stack
          direction="row"
          spacing={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {/* <Tooltip title="Dsouza, Keith">
            <span>
              <StringAvatar username="Dsouza, Keith" />
            </span>
          </Tooltip> */}

          <div>
            <Tooltip
              arrow
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              placement="bottom"
              title={'Logged in as ' + accounts[0]?.name}
            >
              <span>
                {isAuthenticated ? (
                  <StringAvatar username={accounts[0]?.name} />
                ) : (
                  <AccountCircleIcon
                    sx={{ color: 'black', fontSize: '35px', cursor: 'pointer' }}
                  />
                )}
              </span>
            </Tooltip>
          </div>

          <SignOutButton />
        </Stack>
      </div>
    </nav>
  );
}

export default Header;
