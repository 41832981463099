import { Popper, styled } from '@mui/material';

// Create a styled Popper component
export const StyledPopper = styled(Popper)(({ theme }) => ({
  '& .MuiAutocomplete-paper': {
    backgroundColor: 'var(--search_bar_dark_bg)',
    color: 'var(--region_text_color)',
  },
  // '& .MuiAutocomplete-listbox .MuiAutocomplete-option': {
  //     borderBottom: '0.5px solid white',
  // },
}));
