import React, { useCallback, useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Box, Button, Fade, Modal, Tooltip, Typography } from '@mui/material';
import lowResDarkModePicture from './images/low-res/lowResDarkMode.png';
import lowResLightModePicture from './images/low-res/lowResLightMode.png';
import 'react-lazy-load-image-component/src/effects/blur.css';
import darkModePicture from './images/darkMode.png';
import lightModePicture from './images/lightMode.png';

import ReusableDropdown from '../ReusableDropdown/ReusableDropdown';
import {
  optionsCSP,
  optionsDeviceType,
  optionsRegion,
  optionsRegionAWS,
  optionsRegionAZURE,
  optionsRegionGCP,
  userPreferenceDropdownOptions,
} from '../SubscriptionModal/Mapping/MappingList';
import {
  styleConfirmationModal,
  userPreferenceModalBoxStyle,
} from '../SubscriptionModal/Mapping/subscriptionModalBoxStyle';

import './UserPreferences.css'; // Import the required CSS
import '../SubscriptionModal/subscriptionModal.css';
import '../../components/MobileSubscriptionModal/mobileSubscriptionModal.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useDispatch, useSelector } from 'react-redux';
import { handleDefaultViewUpdate } from '../../components/UserPreferences/utils/handleDefaultViewUpdate';
import {
  setHardRefresh,
  setTheme,
  setUserDefaultThemeView,
} from '../../redux/userPreferencesSlice';

const MobileUserPreferences = ({ open, onClose }) => {
  //   const [theme, setTheme] = useState("light");
  const [categoryDropdown, setCategoryDropdown] = useState(null);
  const [regionDropdown, setRegionDropdown] = useState(null);
  const [cspDropDown, setCspDropDown] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [yesClicked, setYesClicked] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState();
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  //current screen width
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const userCustomDefaultView = useSelector(state => state.userInfo) || {};

  const emailId = userCustomDefaultView.userInfo?.email;
  //===============================================

  // Dark + Light Mode related stuff
  const setDarkMode = useCallback(() => {
    document.querySelector('html').setAttribute('data-theme', 'dark');
    document.querySelector('body').setAttribute('data-theme', 'dark');
    document.body.style.backgroundColor = '#292929';
    localStorage.setItem('selectedTheme', 'dark');
    setSelectedTheme('dark');
  }, []);

  const setLightMode = useCallback(() => {
    document.querySelector('html').setAttribute('data-theme', 'light');
    document.querySelector('body').setAttribute('data-theme', 'light');
    document.body.style.backgroundColor = 'white';
    localStorage.setItem('selectedTheme', 'light');
    setSelectedTheme('light');
  }, []);

  //   useEffect(() => {
  //     const savedTheme = localStorage.getItem("selectedTheme");
  //     console.log("got theme",savedTheme)
  //     if (savedTheme) {
  //       setSelectedTheme(savedTheme);
  //     //   setTheme(savedTheme);
  //     }
  //   }, []);

  //   useEffect(() => {
  //     if (selectedTheme === "dark") {
  //       setDarkMode();
  //     } else {
  //       setLightMode();
  //     }
  //   }, [selectedTheme, setDarkMode, setLightMode]);

  useEffect(() => {
    const savedTheme = localStorage.getItem('selectedTheme');
    if (savedTheme) {
      setSelectedTheme(savedTheme);
      if (savedTheme === 'dark') {
        setDarkMode();
      } else {
        setLightMode();
      }
    } else {
      setLightMode(); // Default to light mode if no theme is saved
    }
  }, [setDarkMode, setLightMode]);

  //   useEffect(()=>{
  //     console.log(selectedTheme,"selectedTheme")
  //   },[selectedTheme])

  const toggleTheme = theme => () => {
    if (theme === 'dark') {
      dispatch(setTheme('dark'));
      setIsSaveDisabled(false);
      setDarkMode();
      // setTheme("dark");
    } else if (theme === 'light') {
      setLightMode();
      setIsSaveDisabled(false);
      dispatch(setTheme('light'));
      // setTheme("light");
    }
  };

  //End of Dark + Light Mode related stuff

  //===================================

  const openConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const initialState = {
    theme: 'light',
    defaultViewCSP: 'AWS',
    defaultViewRegion: 'americas',
  };

  // Handler for the first dropdown selection
  const handleChangeCategory = useCallback(selectedOption => {
    setSelectedOption(selectedOption);
    // Reset dependent dropdowns when the main dropdown changes
    setRegionDropdown('');
    setCspDropDown('');
    setDeviceType('');
    setHasUnsavedChanges(true);
    handleUpdateCategory(selectedOption);
  }, []);

  const handleUserPreferenceSave = async () => {
    if (!emailId) {
      console.error('Email ID is not defined');
      return; // Exit if emailId is not valid
    }

    // Construct the payload for the API request
    let updatedPreferences = {
      theme: {
        mode: selectedTheme, // Use the theme mode (dark/light)
      },
      view: {
        preferred: {
          category_name:
            categoryDropdown ??
            userCustomDefaultView?.userDefaultThemeView?.view?.preferred
              ?.category_name,
          region:
            regionDropdown ??
            userCustomDefaultView?.userDefaultThemeView?.view?.preferred
              ?.region,
          csp:
            cspDropDown ??
            userCustomDefaultView?.userDefaultThemeView?.view?.preferred?.csp,
          device_type:
            deviceType ??
            userCustomDefaultView?.userDefaultThemeView?.view?.preferred
              ?.device_type,
          exist: categoryDropdown?.length === 0 ? false : true, // Set the 'exist' flag to true
        },
      },
    };

    // Construct payload based on selected category name
    switch (categoryDropdown) {
      case 'CMP':
        // No additional fields required, others should remain null
        updatedPreferences.view.preferred.region = null;
        updatedPreferences.view.preferred.csp = null;
        updatedPreferences.view.preferred.device_type = null;
        break;

      case 'AWS':
        // AWS requires region
        updatedPreferences.view.preferred.region = regionDropdown;
        updatedPreferences.view.preferred.csp = null;
        updatedPreferences.view.preferred.device_type = null;
        break;

      case 'GCP':
        // GCP requires region
        updatedPreferences.view.preferred.region = regionDropdown;
        updatedPreferences.view.preferred.csp = null;
        updatedPreferences.view.preferred.device_type = null;
        break;

      case 'Cloud Network':
        // Cloud Network requires all fields to be filled
        updatedPreferences.view.preferred.region = regionDropdown;
        updatedPreferences.view.preferred.csp = cspDropDown;
        updatedPreferences.view.preferred.device_type = deviceType;
        break;

      case 'AZURE':
        // Azure requires region
        updatedPreferences.view.preferred.region = regionDropdown;
        updatedPreferences.view.preferred.csp = null;
        updatedPreferences.view.preferred.device_type = null;
        break;

      default:
        break;
    }

    try {
      // Call handleDefaultViewUpdate to perform the update with toast notifications
      const updatedResponse = await handleDefaultViewUpdate(emailId, {
        ...updatedPreferences,
        mode: updatedPreferences.theme.mode,
        preferred: updatedPreferences.view.preferred,
      });
      dispatch(setUserDefaultThemeView(updatedPreferences));
      dispatch(setHardRefresh());
      // Reset the unsaved changes flag and close the modal
      setHasUnsavedChanges(false);
      onClose(); // Close the modal
      return updatedResponse;
    } catch (error) {
      console.error('Error updating user preferences:', error);
    }
    setHasUnsavedChanges(false); // Reset the unsaved changes flag
    onClose(); // Close the modal
  };

  const handleUpdateCategory = useCallback(category => {
    setCategoryDropdown(category);
    setHasUnsavedChanges(true);
  }, []);
  const handleUpdateRegion = useCallback(region => {
    setRegionDropdown(region);
    setHasUnsavedChanges(true);
  }, []);

  const handleUpdateDeviceType = useCallback(device => {
    setDeviceType(device);
    setHasUnsavedChanges(true);
  }, []);
  const handleUpdateCsp = useCallback(csp => {
    setCspDropDown(csp);
    setHasUnsavedChanges(true);
  }, []);

  const handleModalClose = useCallback(() => {
    if (hasUnsavedChanges) {
      openConfirmDialog();
    } else {
      handleClose();
    }
  }, [hasUnsavedChanges, openConfirmDialog]);

  const dropdownInfoCSP = {
    region: {
      placeholder: 'Select Region',
      values: optionsRegion,
      disabled: false,
      updateMethod: handleUpdateRegion,
      description: 'Select a Region',
    },
    deviceType: {
      placeholder: 'Select Device Type',
      values: optionsDeviceType,
      disabled: !regionDropdown || regionDropdown?.length === 0,
      updateMethod: handleUpdateDeviceType,
      description: 'Choose to subscribe to Palo Alto/F5 device alerts',
    },

    csp: {
      placeholder: 'Select a CSP',
      values: optionsCSP,
      disabled: !(deviceType && deviceType?.length > 0),
      updateMethod: handleUpdateCsp,
      description: 'Select your preferred Cloud Service Provider',
    },
  };
  const dropdownInfoAZURE = {
    region: {
      placeholder: 'Select Region',
      values: optionsRegionAZURE.filter(e => e.active),
      disabled: false,
      updateMethod: handleUpdateRegion,
      description:
        'This option allows you to filter products/services in different parts of the world',
    },
  };
  const dropdownInfoGCP = {
    region: {
      placeholder: 'Select a Region',
      values: optionsRegionGCP,
      disabled: false,
      updateMethod: handleUpdateRegion,
      description:
        'This option allows you to filter products/services in different parts of the world',
    },
  };
  const dropdownInfoAWS = {
    region: {
      placeholder: 'Select a Region',
      values: optionsRegionAWS,
      disabled: false,
      updateMethod: handleUpdateRegion,
      description:
        'This option allows you to filter products/services in different parts of the world',
    },
  };

  const handleYesClickedAndDiscardChanges = async () => {
    setConfirmDialogOpen(false);
    setHasUnsavedChanges(false);
    setSelectedOption('');
    setRegionDropdown('');
    setCspDropDown('');
    setDeviceType('');
    handleClose();
  };

  useEffect(() => {
    checkForUnsavedChanges();
  }, [categoryDropdown, regionDropdown, cspDropDown, deviceType]);

  // Function to track unsaved changes
  const checkForUnsavedChanges = () => {
    if (
      //   theme !== initialState.theme ||
      categoryDropdown !== '' ||
      regionDropdown !== '' ||
      cspDropDown !== '' ||
      deviceType !== ''
    ) {
      setHasUnsavedChanges(true);
    } else {
      setHasUnsavedChanges(false);
    }
  };

  useEffect(() => {
    checkForUnsavedChanges();
    // }, [theme, categoryDropdown, regionDropdown, cspDropDown, deviceType]);
  }, [categoryDropdown, regionDropdown, cspDropDown, deviceType]);

  // Update the theme styles on change
  //   useEffect(() => {
  //     if (theme === "light") {
  //       document.body.classList.remove("dark-mode");
  //     } else if (theme === "dark") {
  //       document.body.classList.add("dark-mode");
  //     }
  //   }, [theme]);

  const handleClose = () => {
    if (hasUnsavedChanges) {
      const confirmClose = window.confirm(
        'You have unsaved changes. Are you sure you want to close?',
      );
      if (!confirmClose) return;
    }
    onClose();
  };

  //usEffect to set the screen width
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    switch (selectedOption) {
      case 'Cloud Network':
        setIsSaveDisabled(!(regionDropdown && cspDropDown && deviceType));
        break;
      case 'AWS':
      case 'GCP':
      case 'AZURE':
        setIsSaveDisabled(!regionDropdown);
        break;
      case 'CMP':
        setIsSaveDisabled(false); // No additional fields required, so keep disabled
        break;
      default:
        setIsSaveDisabled(true); // No selection made
    }
  }, [selectedOption, regionDropdown, cspDropDown, deviceType]);

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box
          sx={userPreferenceModalBoxStyle(screenWidth)}
          className="userpreference-modal-content"
        >
          <div className="subscription-modal-main-container">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
              }}
            >
              <button
                className="userpreference-mac-close-button"
                onClick={handleClose}
              ></button>
            </div>
            {/* Theme Preference Section */}
            <div className="userpreference-section">
              <div
                className="userpreference-theme-preference-header"
                style={{ textAlign: 'left' }}
              >
                <label className="userpreference-theme-preference-header">
                  Theme Preference
                </label>
                <p className="userpreference-description">
                  Change how the ServiceStatus Dashboard looks in your browser
                </p>
              </div>

              <div className="userpreference-theme-previews">
                {/* Light Theme */}
                <button
                  className={`userpreference-theme-preview ${selectedTheme === 'light' ? 'selected' : ''}`}
                  onClick={toggleTheme('light')}
                  onKeyDown={e => {
                    if (e.key === 'Enter') toggleTheme('light');
                  }}
                  aria-label="Select light theme"
                >
                  <LazyLoadImage
                    src={lightModePicture}
                    alt="Light theme preview"
                    className="userpreference-theme-img"
                    effect="blur" // Apply blur effect while loading
                    placeholderSrc={lowResLightModePicture} // Optional: Placeholder image
                  />
                  <span className="userpreference-image-label">Light</span>
                </button>

                {/* Dark Theme */}
                <button
                  className={`userpreference-theme-preview ${selectedTheme === 'dark' ? 'selected' : ''}`}
                  onClick={toggleTheme('dark')}
                  onKeyDown={e => {
                    if (e.key === 'Enter') toggleTheme('dark');
                  }}
                  aria-label="Select dark theme"
                >
                  <LazyLoadImage
                    src={darkModePicture}
                    alt="Dark theme preview"
                    className="userpreference-theme-img"
                    effect="blur" // Apply blur effect while loading
                    placeholderSrc={lowResDarkModePicture} // Optional: Placeholder image
                  />
                  <span className="userpreference-image-label">Dark</span>
                </button>
              </div>
            </div>

            {/* Default View Section */}
            <div className="userpreference-section-defaultView">
              <div
                className="userpreference-theme-preference-header"
                style={{ textAlign: 'left', marginBottom: '5px' }}
              >
                <label
                  htmlFor="default-view-select"
                  className="userpreference-theme-preference-header"
                >
                  Default View (After Sign In)
                </label>
              </div>
              <p className="userpreference-description">
                Based on your preferred choice of Category and Location, choose
                a combination that best suits your needs for monitoring health
                status.
              </p>
              <div className="reusable-dropdown-container-mobile">
                <div className="reusable-dropdown-left-label-container-mobile">
                  <Typography className="leftLabel-mobile-smaller-font">
                    Select Category -
                  </Typography>
                  <Tooltip
                    arrow
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 600 }}
                    fontSize="small"
                    placement="top"
                    title="Select a Category"
                  >
                    <span>
                      <InfoOutlinedIcon className="info-icon-alerts-modal" />
                    </span>
                  </Tooltip>
                </div>
                <div className="reusable-dropdown-right-dropdown-container-mobile">
                  <ReusableDropdown
                    placeholder="Select a Category"
                    values={userPreferenceDropdownOptions}
                    disabled={false}
                    onChangeUpdate={handleChangeCategory}
                  />
                </div>
              </div>
              {/* Scrollable container for dependent dropdowns */}
              <div
                className="userpreference-dynamic-dropdown-container"
                style={{ width: '100%' }}
              >
                {/* Render dropdowns based on the selected option */}
                {selectedOption === 'Cloud Network' && (
                  <div>
                    <div>
                      {Object.keys(dropdownInfoCSP).map(key => (
                        <div
                          className="reusable-dropdown-container-mobile"
                          key={key}
                        >
                          <div className="reusable-dropdown-left-label-container-mobile">
                            <Typography className="leftLabel-mobile-smaller-font">
                              {dropdownInfoCSP[key].placeholder} -
                            </Typography>

                            <Tooltip
                              arrow
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              fontSize="small"
                              placement="top"
                              title={dropdownInfoCSP[key].description}
                            >
                              <span>
                                <InfoOutlinedIcon className="info-icon-alerts-modal" />
                              </span>
                            </Tooltip>
                          </div>
                          <div className="reusable-dropdown-right-dropdown-container-mobile">
                            <ReusableDropdown
                              placeholder={dropdownInfoCSP[key].placeholder}
                              values={dropdownInfoCSP[key].values}
                              disabled={dropdownInfoCSP[key].disabled}
                              onChangeUpdate={dropdownInfoCSP[key].updateMethod}
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                {/* Render only Region dropdown for aws, gcp, or azure */}

                {selectedOption === 'GCP' && (
                  <div>
                    {Object.keys(dropdownInfoGCP).map(key => (
                      <div
                        className="reusable-dropdown-container-mobile"
                        key={key}
                      >
                        <div className="reusable-dropdown-left-label-container-mobile">
                          <Typography className="leftLabel-mobile-smaller-font">
                            {dropdownInfoGCP[key].placeholder} -
                          </Typography>
                          <Tooltip
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            fontSize="small"
                            placement="top"
                            title={dropdownInfoGCP[key].description}
                          >
                            <span>
                              <InfoOutlinedIcon className="info-icon-alerts-modal" />
                            </span>
                          </Tooltip>
                        </div>
                        <div className="reusable-dropdown-right-dropdown-container-mobile">
                          <ReusableDropdown
                            placeholder={dropdownInfoGCP[key].placeholder}
                            values={dropdownInfoGCP[key].values}
                            disabled={dropdownInfoGCP[key].disabled}
                            onChangeUpdate={dropdownInfoGCP[key].updateMethod}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {selectedOption === 'AZURE' && (
                  <div>
                    {Object.keys(dropdownInfoAZURE).map(key => (
                      <div
                        className="reusable-dropdown-container-mobile"
                        key={key}
                      >
                        <div className="reusable-dropdown-left-label-container-mobile">
                          <Typography className="leftLabel-mobile-smaller-font">
                            {dropdownInfoAZURE[key].placeholder} -
                          </Typography>
                          <Tooltip
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            fontSize="small"
                            placement="top"
                            title={dropdownInfoAZURE[key].description}
                          >
                            <span>
                              <InfoOutlinedIcon className="info-icon-alerts-modal" />
                            </span>
                          </Tooltip>
                        </div>
                        <div className="reusable-dropdown-right-dropdown-container-mobile">
                          <ReusableDropdown
                            placeholder={dropdownInfoAZURE[key].placeholder}
                            values={dropdownInfoAZURE[key].values}
                            disabled={dropdownInfoAZURE[key].disabled}
                            onChangeUpdate={dropdownInfoAZURE[key].updateMethod}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {selectedOption === 'AWS' && (
                  <div>
                    {Object.keys(dropdownInfoAWS).map(key => (
                      <div
                        className="reusable-dropdown-container-mobile"
                        key={key}
                      >
                        <div className="reusable-dropdown-left-label-container-mobile">
                          <Typography className="leftLabel-mobile-smaller-font">
                            {dropdownInfoAWS[key].placeholder} -
                          </Typography>
                          {/* <Typography className="description-label" fontSize="small"> */}
                          <Tooltip
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            fontSize="small"
                            placement="top"
                            title={dropdownInfoAWS[key].description}
                          >
                            <span>
                              <InfoOutlinedIcon className="info-icon-alerts-modal" />
                            </span>
                          </Tooltip>
                        </div>
                        <div className="reusable-dropdown-right-dropdown-container-mobile">
                          <ReusableDropdown
                            placeholder={dropdownInfoAWS[key].placeholder}
                            values={dropdownInfoAWS[key].values}
                            disabled={dropdownInfoAWS[key].disabled}
                            onChangeUpdate={dropdownInfoAWS[key].updateMethod}
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                )}

                {/* Render no dropdowns if CMP is selected */}
                {selectedOption === 'CMP' && (
                  <div>
                    <h4>No additional options available for CMP</h4>
                  </div>
                )}
              </div>
            </div>

            {/* Buttons */}
            <div className="userpreference-modal-actions">
              <button
                className="userpreference-cancel-button"
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                className="userpreference-save-button"
                onClick={handleUserPreferenceSave}
                disabled={isSaveDisabled}
              >
                Save
              </button>
            </div>
          </div>
          <Modal
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
            aria-labelledby="confirm-dialog-title"
            aria-describedby="confirm-dialog-description"
          >
            <Fade in={confirmDialogOpen}>
              <Box
                style={styleConfirmationModal}
                className="confirmation-dialog"
              >
                <Typography
                  id="confirm-dialog-title"
                  variant="h6"
                  sx={{ marginBottom: 2 }}
                >
                  Are you sure?
                </Typography>
                <Typography
                  id="confirm-dialog-description"
                  sx={{ marginBottom: 4 }}
                >
                  You have unsaved changes. Do you want to discard them?
                </Typography>
                <div className="confirmation-dialog-buttons">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleYesClickedAndDiscardChanges}
                    sx={{
                      marginRight: 2,
                      color: 'red',
                      backgroundColor: 'white',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: 'red',
                      },
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{
                      color: 'green',
                      backgroundColor: 'white',
                      '&:hover': {
                        color: 'white',
                        backgroundColor: 'green',
                      },
                    }}
                    onClick={() => {
                      setConfirmDialogOpen(false);
                      setYesClicked(false);
                    }}
                  >
                    No
                  </Button>
                </div>
              </Box>
            </Fade>
          </Modal>
        </Box>
      </Fade>
    </Modal>
  );
};
export default MobileUserPreferences;
