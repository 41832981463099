import * as React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MuiAppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import { mainListItems, secondaryListItems } from './listItems';
import { mockMICData } from './mockMICData';

import MicBarChart from '../../components/MIC/Charts/BarChart';
import MICComposedChart from '../../components/MIC/Charts/ComposedChart';
import MICBarChartRitmsMF from '../../components/MIC/Charts/MICBarChartRitmsMF';
import MicPieChart from '../../components/MIC/Charts/PieChart';
import MajorAccomplishments from '../../components/MIC/Table/MajorAccomplishments';
import MajorIncidentsTables from '../../components/MIC/Table/MajorIncidents';

import './mic-report.css';
import { useState } from 'react';
import axios from 'axios';
import { useEffect } from 'react';
import { CalendarMonth } from '@mui/icons-material';
import { Modal } from '@mui/material';
import RangeCalendar from './calendarRange';
import { useParams } from 'react-router-dom';
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const defaultTheme = createTheme();

function getWeekRange(date = new Date()) {
  // Create a copy of the date object so as not to modify the original date
  let currentDate = new Date(date);

  // Get the current day of the week (0 = Sunday, 1 = Monday, ..., 6 = Saturday)
  let dayOfWeek = currentDate.getDay();

  // Calculate the difference to get the start of the week (Monday)
  let startOfWeek = new Date(currentDate);
  startOfWeek.setDate(
    currentDate.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1),
  ); // Adjust for Sunday

  // Calculate the end of the week (Sunday)
  let endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);

  // Format the dates as strings (e.g., "January 1, 2024")
  let options = { year: 'numeric', month: 'long', day: 'numeric' };
  let startString = startOfWeek.toLocaleDateString(undefined, options);
  let endString = endOfWeek.toLocaleDateString(undefined, options);

  return `Week of ${startString} to ${endString}`;
}

export default function HistoricalDataDashboard() {
  //  Change title of the page
  document.title = 'MIC Report Dashboard';
  const params = useParams();
  const startDate = params.start_date;
  const result = mockMICData;
  const [mockData, setMockData] = React.useState(result);
  const [open, setOpen] = React.useState(false);
  const [micReportData, setMicReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openCalendar, setOpenCalendar] = useState(false);
  const fetchMicData = () => {
    setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/api/mic-reports/live`)
      .then(res => {
        setMicReportData(res.data);
      })
      .catch(err => console.log(err))
      .finally(() => setLoading(false));
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
  // Function to create data format dynamically for breached requests
  const createBreachedRequestsData = data => {
    if (!data.requests_breached) {
      return [];
    }
    return data.requests_breached
      .filter(item => item.Has_breached !== 'Total') // Filter out "Total"
      .map(item => ({
        name: item.Has_breached === 'true' ? 'SLA Missed' : 'SLA Achieved',
        value: parseInt(item.Catalog_Request_Item_SLA_Count, 10),
        percentage: item.Percentage_of_Catalog_Request_Item_SLAs,
      }));
  };

  // Function to create data format dynamically for breached incidents
  const createBreachedIncidentsData = data => {
    if (!data.incidents_breached) {
      return [];
    }
    return data.incidents_breached
      .filter(item => item.Ticket_SLA_Breach !== 'Total') // Filter out "Total"
      .map(item => ({
        name: item.Ticket_SLA_Breach === 'true' ? 'SLA Missed' : 'SLA Achieved',
        value: parseInt(item.Incident_Count, 10),
        percentage: item.Percentage_of_Incidents,
      }));
  };

  //   const breachedRequestsData = createBreachedRequestsData(
  //     mockData?.current_data?.SLA_Breached_Data,
  //   );
  //   const breachedIncidentsData = createBreachedIncidentsData(
  //     mockData?.current_data?.SLA_Breached_Data,
  //   );

  useEffect(() => {
    fetchMicData();
  }, []);

  if (loading) {
    return (
      <ThemeProvider theme={defaultTheme}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
        </Box>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px',
              backgroundColor: 'white',
              color: 'black',
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              MIC Report Dashboard
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: theme =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
            >
              {/* <Typography
                variant="h5"
                component="h1"
                gutterBottom
                align="center"
                style={{ color: '#86BC25' }}
              >
                Showing live data for the week of{' '}
                {getWeekRange(new Date(startDate))}{' '}
                <CalendarMonth onClick={() => setOpenCalendar(true)} />
              </Typography> */}

              <Grid container spacing={3}>
                {/* Info */}
                <Grid item xs={12} md={4} lg={2.5}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label className="metric-numbers">
                      {micReportData.current_data?.incidents?.closed}
                    </label>
                    <label className="tile-label">INC Closed </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label className="metric-numbers">
                      {micReportData.current_data?.requests?.open}
                    </label>
                    <label className="tile-label">Active RITM </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label className="metric-numbers">
                      {micReportData.current_data?.incidents?.open}
                    </label>
                    <label className="tile-label">INC Active </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2.5}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label className="metric-numbers">
                      {micReportData.current_data?.requests?.closed}
                    </label>
                    <label className="tile-label">RITM Closed </label>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 200,
                      borderRadius: '1.5rem',
                      justifyContent: 'space-evenly',
                      alignItems: 'center',
                    }}
                  >
                    <label className="metric-numbers">
                      {micReportData.current_data?.mic_handled}
                    </label>
                    <label className="tile-label">MIC Handled </label>
                  </Paper>
                </Grid>
                {/* Requests Handled Opened & Closed */}
                <Grid item xs={12} md={4} lg={8}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    {/* <label className="tile-label">
                      Requests Handled Opened & Closed
                    </label> */}
                    <MicBarChart title={`Requests Handled Opened & Closed`} />
                  </Paper>
                </Grid>
                {/* Requests SLA */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MicPieChart
                      title={'Requests'}
                      data={micReportData?.sla_breached_data?.requests_breached}
                    />
                  </Paper>
                </Grid>
                {/* Incidents SLA */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MicPieChart
                      title={'Incidents'}
                      data={
                        micReportData?.sla_breached_data?.incidents_breached
                      }
                    />
                  </Paper>
                </Grid>
                {/* RITMs by CSP */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MICComposedChart
                      title={'RITMs by CSP'}
                      data={mockData?.current_data?.CSP_Data}
                    />
                  </Paper>
                </Grid>
                {/* RITMs Opened by MF */}
                <Grid item xs={12} md={4} lg={4}>
                  <Paper
                    sx={{
                      p: 1,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                      borderRadius: '1.5rem',
                      minHeight: '300px',
                    }}
                  >
                    <MICBarChartRitmsMF
                      title={'RITMs by CSP'}
                      data={mockData?.current_data?.All_Member_Firms}
                    />
                  </Paper>
                </Grid>
                {/* Major Incidents */}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '300px',
                      borderRadius: '1.5rem',
                    }}
                  >
                    <h4
                      style={{
                        color: '#86BC25',
                      }}
                    >
                      Major Incidents
                    </h4>
                    <MajorIncidentsTables />
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '300px',
                      borderRadius: '1.5rem',
                    }}
                  >
                    <MajorAccomplishments data={micReportData?.csp_data} />
                    <Modal
                      open={openCalendar}
                      onClose={() => setOpenCalendar(false)}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          p: 4,
                          width: '500px',

                          borderRadius: '10px',
                          border: 'none',
                          background: '#FFF',
                          boxShadow:
                            ' 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <RangeCalendar />
                      </Box>
                    </Modal>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
