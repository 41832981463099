import React, { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import '../../../pages/MicReport/mic-report.css';

const MICBarChartRitmsMF = ({ data, title }) => {
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   // Simulate data fetching
  //   const fetchData = async () => {
  //     // Simulate a delay
  //     await new Promise(resolve => setTimeout(resolve, 2000));
  //     setLoading(false);
  //   };

  //   fetchData();
  // }, []);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner"></div>
      </div>
    ); // Display a loading indicator
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <h4
        style={{
          color: '#046a38',
          textAlign: 'center',
          marginBottom: '15px',
        }}
      >
        {title}
      </h4>
      <ResponsiveContainer width="100%" height="90%">
        <AreaChart
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 0,
            left: 0,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" stroke="#ccc" /> */}
          <XAxis dataKey="mf_code" stroke="#ccc" />
          <YAxis stroke="#ccc" />
          <Tooltip />
          <Legend verticalAlign="bottom" height={25} />

          {/* Area for REQ Created */}
          <Area
            type="linear"
            dataKey="requested_item_count"
            name="Requested Items"
            stroke=""
            fill="#046a38"
            strokeWidth={3}
          >
            <LabelList
              dataKey="requested_item_count"
              position="top"
              fill="#000"
              fontWeight="bold"
              fontSize="12px"
            />
          </Area>
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MICBarChartRitmsMF;
