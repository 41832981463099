import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SettingsIcon from '@mui/icons-material/Settings';
import { Container } from '@mui/material';
import Stack from '@mui/material/Stack';
import axios from 'axios';
import Joyride, { STATUS } from 'react-joyride';
import UserPreferences from '../../components/UserPreferences/UserPreferences';
import InstructionModal from '../Carousel/InstructionModal';
import LegendList from '../LegendList/LegendList';
import MobileSubscriptionModal from '../MobileSubscriptionModal/MobileSubscriptionModal';
import MobileUserPreferences from '../UserPreferences/MobileUserPreferences';
import 'react-lazy-load-image-component/src/effects/blur.css';

import '../../LoaderSpinner/loaderspinner.css';
import '../../pages/Insights/insights.css';

function MobileInsights(props) {
  const { rawPreferences } = useSelector(state => state.userInfo) || {};

  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState(['']);
  const fetchAlertsUrl = process.env.REACT_APP_BACKEND_URL;
  const [openModal, setOpenModal] = useState(false);
  const [preferenceLoading, setPreferenceLoading] = useState(true);
  const [isUserPreferenceModalOpen, setIsUserPreferenceModalOpen] = useState(false);
  const { userInfo } = useSelector(state => state.userInfo) || {};
  const { username } = userInfo || {};
  const userCustomDefaultView = useSelector(state => state.userInfo) || {};
  const { userDefaultThemeView } = userCustomDefaultView || {};
  const [runTour, setRunTour] = useState(false);

  // Close User Preference Modal
  const handleCloseUserPreferenceModal = () => {
    setIsUserPreferenceModalOpen(false);
  };

  useEffect(() => {
    if (rawPreferences && rawPreferences?.length > 0) {
      setPreferenceLoading(false);
    } else {
      setTimeout(() => {
        setPreferenceLoading(false);
      }, 2000);
    }
  }, [rawPreferences]);

  // Close Subscription Modal
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  // Open Subscription Modal
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const fetchAlertsData = async () => {
    axios
      .get(`${fetchAlertsUrl}/api/others/alerts`)
      .then(res => {
        if (res) {
          setLoading(false);
          setAlerts(res.data);
        }
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    fetchAlertsData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAlertsData();
    }, 90000);

    // Clearing the interval
    return () => clearInterval(interval);
  }, [alerts]);

  useEffect(() => {
    if (!preferenceLoading && userDefaultThemeView?.view?.preferred?.exists === false) {
      setTimeout(() => {
        setRunTour(true);
      }, 4000); // 3-second delay before starting the tour
    }
  }, [preferenceLoading, userDefaultThemeView]);

  // React Joyride Tour Steps
  const tourSteps = [
    {
      target: '#settings-icon',
      content: (
        <div>
          Hey there {username}! <br />
          Looks like you haven&#39;t set any preferences. <br />
          Please click the settings icon to set your theme and default view.
        </div>
      ),
      disableBeacon: true,
      placement: 'bottom',
    },
  ];

  return (
    <>
      <Joyride
        steps={tourSteps}
        run={runTour}
        continuous={true}
        showSkipButton={true}
        styles={{
          options: {
            zIndex: 10000,
          },
          tooltipContent: {
            padding: '10px 0', // Adjust padding as needed
          },
          buttonNext: {
            marginTop: '0',
            backgroundColor: '#86bc25',
            color: 'white',
          },
          tooltipFooter: {
            marginTop: '0', // Remove margin-top
          },
        }}
        locale={{
          last: 'Close', // Text on the last button
        }}
        callback={(data) => {
          const { status } = data;
          if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
          }
        }}
      />
      <MobileSubscriptionModal
        open={openModal}
        handleClose={handleCloseModal}
      // usersName={usersData}
      />

      <div className="insights-container mobile-insights">
        <div className="insight-flex">
          <p className="service-insight-label-new">Service Health Insights</p>{' '}
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button
              className="updates-btn"
              onClick={handleOpenModal}
              disabled={preferenceLoading}
            >
              <p className="updates-txt">
                {preferenceLoading ? ' Loading..' : 'Subscribe'}
              </p>
            </Button>

            <div>
              <SettingsIcon
                id="settings-icon"
                className={`spin-icon`}
                style={{ cursor: 'pointer' }}
                onClick={() => setIsUserPreferenceModalOpen(true)}
                data-tip="Click here to set your preferences"
              />
              {isUserPreferenceModalOpen && (
                <MobileUserPreferences
                  open={isUserPreferenceModalOpen}
                  onClose={handleCloseUserPreferenceModal}
                />
              )}
            </div>
          </div>
        </div>

        {loading ? (
          <div className="loader-container">
            <div className="loader" data-testid="loader"></div>
          </div>
        ) : (
          <>
            {' '}
            <Container className="services-affected-container">
              {alerts?.length > 0 && !loading ? (
                <div
                  className="alerts-container-mobile"
                  data-testid="alerts-container"
                >
                  <InstructionModal alerts={alerts} />
                </div>
              ) : (
                <div className="card-alert-container">
                  <Stack direction="row" className="success-flex-alert">
                    <div className="info-description">
                      {' '}
                      <p className="operational-text">
                        All Services are Operational today
                      </p>
                    </div>
                  </Stack>
                </div>
              )}
            </Container>
          </>
        )}

        <LegendList />
      </div>
    </>
  );
}

export default MobileInsights;