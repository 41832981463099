export const gcpRegions = [
    { region: 'global', label: 'Global' },
    { region: 'americas', label: 'Americas' },
    { region: 'europe', label: 'Europe' },
    { region: 'africa', label: 'Africa' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
    { region: 'middle_east', label: 'Middle East' },
];

export const gcpRegionsMapper = {
    0: 'global',
    1: 'americas',
    2: 'europe',
    3: 'africa',
    4: 'asia_pacific',
    5: 'middle_east',
};
