import React from 'react';

import successGIF from './success.gif';
import { userThemeDefaultViewFetch } from '../../../redux/userPreferenceFetch';
import toast from 'react-hot-toast';
export const handleDefaultViewFetch = async (
  currentUser
) => {
  try {
    // Show a loading toast
    const loadingToast = toast.loading('Fetching your UI preferences', {
      style: {
        minWidth: '300px', // Increase the minimum width as needed
      },
    });

    const userDefaultView = await userThemeDefaultViewFetch(currentUser);

    // Delay for 1 second (1000 milliseconds) for better UI perspective
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Dismiss the loading toast after the artificial delay
    toast.dismiss(loadingToast);

    // Check if the API response indicates success
    if (userDefaultView?.theme?.mode && userDefaultView?.view) {
      // Show a success toast when the update was successful
      if (userDefaultView?.view?.preferred?.exists === false) {
        toast(
          <div>
            <img
              src={successGIF}
              height={'40px'}
              width={'40px'}
              alt="Success Icon"
            />
            User Preferences not set. Default settings applied.
          </div>,
          {
            duration: 5000,
            style: {
              minWidth: 'fit-content', // Increase the minimum width as needed
            },
          },
        );
      }

      else {
        toast(
          <div>
            <img
              src={successGIF}
              height={'40px'}
              width={'40px'}
              alt="Success Icon"
            />
            Successfully fetched your UI preferences! ✨
          </div>,
          {
            duration: 5000,
            style: {
              minWidth: 'fit-content', // Increase the minimum width as needed
            },
          },
        );
      }
    }

    else {
      // Show an error toast when the update was not successful
      toast.error(
        'Failed to fetch UI preferences. Please try again.',
        {
          duration: 6000,
          style: {
            minWidth: 'fit-content', // Increase the minimum width as needed
          },
        },
      );
    }

    // // Dismiss the loading toast after the artificial delay
    // toast.dismiss(loadingToast);

    // Return the userDefaultView preferences
    return userDefaultView;
  } catch (err) {
    // Show an error toast if there was an error with the API request
    toast.error(`An error occurred: ${err.toString()}`, {
      style: {
        minWidth: '300px', // Increase the minimum width as needed
      },
    });
  }
};
