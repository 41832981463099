// CSS for the loader
export const modalLoaderStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: '#00000024',
  justifyContent: 'center',
  zIndex: 9999, // Ensure the loader appears above other content
};

// CSS for the blurred background
export const modalLoaderBlurredBackgroundStyle = {
  filter: 'blur(3px)', // Adjust the blur amount as needed
};
