import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  maxHeight: 600,
  overflowY: 'auto',
  bgcolor: 'background.paper',
  border: '2px solid green',
  boxShadow: 24,
  borderRadius: 3,
  p: 4,
};

export default function IssueBox({ open, setOpen, issues }) {
  const handleClose = () => setOpen(false);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        role="presentation"
        data-testid="issue-modal"
      >
        <Box sx={style}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {issues?.issueDescription &&
              issues?.issueDescription?.map(item => {
                const { title, Description, pubDate } = item;
                return (
                  <div
                    style={{
                      backgroundColor: '#eeeeee',
                      margin: '10px 0 10px 0',
                      padding: '1rem',
                      borderRadius: '5px',
                    }}
                    key={title}
                  >
                    <p>{title}</p>
                    <p>
                      Published Date :
                      {pubDate ?? new Date(issues?.updatedAt)?.toUTCString()}
                    </p>
                    <p style={{ lineHeight: '40px' }}>
                      Description :{' '}
                      {/* {JSON.stringify(item)?.replace("Our","Concerned")?.split(" ").join("\n")} */}
                      {JSON.stringify(item)?.replace('Our', 'Concerned')}
                    </p>
                  </div>
                );
              })}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
}
