import React from 'react';
import { FormLabel } from 'react-bootstrap';

import error from '../../info-logos/Error-display.png';
import information from '../../info-logos/Info-display.png';
import success from '../../info-logos/Success-display.png';
import warning from '../../info-logos/Warning-display.png';

import './legendlist.css';
function LegendList() {
  const legend_list = [
    {
      image: success,
      msg: 'Available',
      title: 'Service available and accessible to customers',
    },
    {
      image: information,
      msg: 'Service Information',
      title: 'Service Operational but maintenance planned',
    },
    {
      image: warning,
      msg: 'Service Degradation',
      title: 'Performance affected but Service is available',
    },
    {
      image: error,
      msg: 'Service Outage',
      title: 'Interruption in service that renders it unavailable',
    },
  ];
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        width: '100%',
      }}
    >
      <div className="legend-display" data-testid="legend-test">
        {legend_list.map((legend, index) => (
          <div className="legend-container" key={index}>
            <span className="legend-title" title={legend.title}>
              <img
                className="info-img-display"
                src={legend.image}
                alt="error"
              ></img>
              <p className="legend-label">{legend.msg}</p>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
}

export default LegendList;
