import React from 'react';

import './saveButton.css';
const SaveButton = ({ handleSave, mobileStyle, text }) => {
  return (
    <button
      className="save-button"
      onClick={handleSave}
      style={mobileStyle}
      data-testid="save-button"
    >
      {text}
    </button>
  );
};

export default SaveButton;
