import React, { useEffect, useState } from 'react';
import { CartesianGrid, Line, LineChart, Tooltip, XAxis } from 'recharts';

import '../LineGraph/linegraph.css';

const formatXAxis = tickItem => {
  return new Date(tickItem).toUTCString().split(' ')[4]?.slice(0, 3) + '00';
};
const getColor = availability => {
  if (availability === 1) {
    return '#86bc25';
  } else if (availability < 10) {
    return 'red';
  } else if (availability > 11 && availability < 90) {
    return '#ed8b00';
  } else {
    return '#86bc25';
  }
};

const CustomTooltip = ({ active, payload, label }) => {
  const { availability, hour, isoTimestamp } =
    (payload[0] && payload[0].payload) || {};

  return (
    <div className="TooltipBox">
      <div className="custom-tooltip">
        <p className="TooltipLabel">DateTime : </p>
        <p className="TooltipData">{isoTimestamp}</p>
      </div>
      <div className="custom-tooltip">
        <p className="TooltipLabel">Availability : </p>

        <p
          className="TooltipData"
          style={{
            color: getColor(availability),
          }}
        >
          {availability}
        </p>
      </div>
    </div>
  );
};

const getScreenWidth = elemId => {
  if (document.getElementById(elemId)) {
    return process.env.REACT_APP_ENV === 'prod'
      ? document.getElementById(elemId).offsetWidth + 25
      : document.getElementById(elemId).offsetWidth + 14;
  }
  return '100%';
};
function LineGraphs({ issues }) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    let load_initiate = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => {
      clearTimeout(load_initiate);
    };
  }, [issues]);
  const { xAxis, yAxis } = { xAxis: 'hour', yAxis: 'availability' };
  return !loading ? (
    <LineChart
      height={70}
      width={getScreenWidth('status-indicator')}
      style={{
        marginLeft: '-15px',
        maxWidth: getScreenWidth('status-indicator'),
      }}
      data={issues}
      margin={{ top: 5, left: 20, right: 3, bottom: 5 }}
    >
      <CartesianGrid
        vertical={false}
        horizontal={false}
        strokeDasharray="#FFFFFF"
        fill="rgba(217, 217, 217, 0.5)"
      />
      <Tooltip content={<CustomTooltip />} />
      <Line
        type="monotone"
        dataKey={yAxis}
        stroke="#00A3E0"
        strokeWidth={2}
        dot={{ stroke: '#00A3E0', strokeWidth: 5 }}
        activeDot={{ r: 4 }}
      />
      <XAxis
        dataKey={xAxis}
        interval="preserveStartEnd"
        tickFormatter={formatXAxis}
        style={{ fontSize: '10px' }}
      />
    </LineChart>
  ) : (
    <div className="loader-graph" data-testid="loader-graph"></div>
  );
}

export default LineGraphs;
