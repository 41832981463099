
export const regions = [
    { region: 'Americas' },
    { region: 'Europe' },
    { region: 'Asia Pacific' },
];



export const regionsMapper = {
    0: 'Americas',
    1: 'Europe',
    2: 'Asia Pacific',
};


export const categoryMapper = {
    0: 'CMP',
    1: 'Cloud Network',
    2: 'Azure',
    3: 'AWS',
    4: 'GCP',
};

export const deviceTypeMapper = [
    { label: 'Palo Alto', value: 'palo-alto' },
    { label: 'F5', value: 'F5' },
];


export const mapCategoryToIndex = {
    CMP: 0,
    'Cloud Network': 1,
    AZURE: 2,
    AWS: 3,
    GCP: 4,
};

